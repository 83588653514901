import React, { FC, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { customBorderRadius, theme } from '../../lib/styles/universal'
import {
    colors,
    RPOrganizationOverview,
    User,
    PickerItem,
} from '@hazadapt-git/public-core-base'
import { getEnvVars } from '../../lib/config'
import { Picker, RPLogo } from '../atoms'
import { pruneString, useWindowSizeUp } from '../../lib/utils'

interface RootWelcomePageTemplateProps {
    allOrgs: PickerItem<string>[]
    userOrgs?: RPOrganizationOverview[]
    publicUser?: User
}
const { rpUriBase } = getEnvVars()

export const RootWelcomePageTemplate: FC<RootWelcomePageTemplateProps> = ({
    allOrgs,
    userOrgs,
    publicUser,
}) => {
    const [showButton, setShowButton] = useState<boolean>(true)
    const { classes: localClasses } = useLocalStyles()
    const lgScreens = useWindowSizeUp('lg')

    const handleLaunchOrganization = (slug: string) => {
        window.location.href = `https://${slug}.${rpUriBase}`
    }

    const renderOrgCards = () => {
        return (
            <List>
                {userOrgs &&
                    userOrgs.map((org) => (
                        <ListItem
                            key={org.id}
                            className={localClasses.orgCard}
                            secondaryAction={
                                <Button
                                    onClick={() =>
                                        handleLaunchOrganization(org.slug)
                                    }
                                    className={localClasses.launchOrgButton}
                                >
                                    Launch
                                </Button>
                            }
                        >
                            <ListItemAvatar>
                                {org.logo?.url || org.logo?.src ? (
                                    <img
                                        className={localClasses.orgLogo}
                                        src={org.logo.url ?? org.logo.src}
                                        alt={
                                            org.logo.alt
                                                ? org.logo.alt
                                                : `${org.name} logo`
                                        }
                                    />
                                ) : (
                                    <div className={localClasses.inset} />
                                )}
                            </ListItemAvatar>
                            <ListItemText
                                primary={pruneString(org.name)}
                                primaryTypographyProps={{
                                    fontSize: '.75rem',
                                    fontWeight: '500',
                                    maxWidth: '60%',
                                }}
                                secondary={pruneString(org.jurisdiction_name)}
                                secondaryTypographyProps={{
                                    color: colors.grays.CHARCOAL,
                                    fontSize: '.7rem',
                                    maxWidth: '60%',
                                }}
                            />
                        </ListItem>
                    ))}
            </List>
        )
    }

    return (
        <div className={localClasses.container}>
            <div className={localClasses.mainContent}>
                <Stack className={localClasses.promoCopy}>
                    {lgScreens && <RPLogo className={localClasses.rpLogo} />}
                    <Typography
                        fontWeight={500}
                        className={localClasses.bulletPoint}
                        mb="3rem"
                    >
                        Empowering community resilience through real-time
                        engagement and insights.
                    </Typography>
                    <Typography
                        fontWeight={500}
                        className={localClasses.bulletPoint}
                    >
                        Why choose ResiliencePoint:
                    </Typography>
                    <List sx={{ pb: '2.5rem' }}>
                        <ListItem>
                            <Typography className={localClasses.bulletPoint}>
                                <strong>Real-Time Insights:</strong> Access
                                up-to-date information on community resilience
                                and coping capacities for various hazards.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className={localClasses.bulletPoint}>
                                <strong>Outreach Impact:</strong> See how your
                                preparedness outreach efforts impact Protective
                                Actions in your community.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography className={localClasses.bulletPoint}>
                                <strong>User-Friendly:</strong> Designed with
                                verified decision-makers in mind, providing an
                                intuitive interface for emergency managers,
                                public safety authorities, and more.
                            </Typography>
                        </ListItem>
                    </List>
                </Stack>
                <div>
                    {!lgScreens && (
                        <RPLogo className={localClasses.smScreenRPLogo} />
                    )}
                    <div className={localClasses.orgPickerContainer}>
                        <Typography
                            alignSelf="center"
                            textAlign="center"
                            variant="h4"
                            component="p"
                            pb="1rem"
                        >
                            Select an organization to launch
                        </Typography>
                        <div className={localClasses.orgPickerContents}>
                            {publicUser && userOrgs && userOrgs.length > 0 && (
                                <div className={localClasses.orgListContainer}>
                                    {renderOrgCards()}
                                </div>
                            )}
                            {!publicUser || !userOrgs || !showButton ? (
                                <Picker
                                    id="add-org-select"
                                    data={allOrgs}
                                    placeholder="Select organization..."
                                    onChange={(value) =>
                                        handleLaunchOrganization(value)
                                    }
                                />
                            ) : (
                                <Button onClick={() => setShowButton(false)}>
                                    Add Organization
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        background: `linear-gradient(to right, ${colors.primary.CERULEAN}, ${colors.secondary.BONDI} 70%)`,
        display: 'flex',
        flex: 1,
        padding: '1rem',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
    },
    mainContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: '2rem',
        justifyContent: 'start',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'start',
            display: 'grid',
            gap: '3.5rem',
            gridTemplateColumns: '3fr 2.5fr',
        },
    },
    rpLogo: {
        alignSelf: 'start',
        width: '100%',
        paddingBottom: '1rem',
    },
    smScreenRPLogo: { padding: '2rem 0', width: '100%' },
    promoCopy: {
        margin: '0 auto',
        width: 'min(100%, 36rem)',
        '& li': {
            '&::before': {
                content: '"\\25CF"',
                color: colors.primary.CORAL,
                width: '1rem',
                marginLeft: '-1rem',
                marginRight: '1rem',
                fontSize: '1rem',
            },
        },
    },
    bulletPoint: {
        color: colors.grays.BLANC,
        lineHeight: '1.25rem',
    },
    orgPickerContainer: {
        backgroundColor: colors.grays.BLANC,
        borderRadius: customBorderRadius,
        boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.15)',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem',
        width: 'min(100%, 29rem)',
    },
    orgPickerContents: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
    },
    orgLogo: { height: '3rem', width: '3rem', objectFit: 'contain' },
    inset: { height: '3rem', width: '3rem' },
    orgListContainer: {
        margin: '1rem 0',
        maxHeight: '13rem',
        overflow: 'scroll',
        padding: '0 .5rem',
        width: '100%',
    },
    orgCard: {
        borderRadius: '10px',
        boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.15)',
        height: 'auto',
        marginBottom: '.75rem',
    },
    launchOrgButton: {
        backgroundColor: colors.grays.BLANC,
        border: `1px solid ${colors.grays.SILVER}`,
        borderRadius: '5px',
        color: colors.grays.CHARCOAL,
        fontSize: '.7rem',
        padding: '.15rem .75rem',
        '&:hover': { backgroundColor: colors.grays.WHITESMOKE },
    },
})
