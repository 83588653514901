import { TrendingHazardDTO, colors } from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import {
    InsightGrid,
    InsightsLoadingOverlay,
    TrendingHazardList,
} from '../organisms'
import { InsightViewProps, LocalContentDataDisclaimer } from '../molecules'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { IoDownload, IoEllipsisVertical, IoReload } from 'react-icons/io5'
import { primaryIconSize, theme } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import { CustomMenuItem } from '../../lib/entities/misc'
import { format } from 'date-fns-tz'

interface HomePageTemplateProps {
    pageTitle: string
    dateRange: string
    trendingHazardData: TrendingHazardDTO[]
    showLocalContentDisclaimer?: boolean
    onReloadPress(): void
    updatedDate?: Date
    onDownloadPress(): void
    insights?: InsightViewProps[]
    quickStatRowTitle?: string
    loading?: boolean
    retrievingInsights?: boolean
    onPressTrendingHazard(id: number): void
}

export const HomePageTemplate: FC<HomePageTemplateProps> = (
    props: HomePageTemplateProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const mdScreens = useWindowSizeUp('md')
    const formattedDate = props.updatedDate
        ? format(props.updatedDate, 'M/d/y @ h:mm aaa')
        : undefined

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const onOptionsClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget)
        },
        []
    )

    const onOptionsClose = () => {
        setAnchorEl(null)
    }

    React.useEffect(() => {
        if (props.loading || props.retrievingInsights) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [props.loading, props.retrievingInsights])

    return (
        <div className={localClasses.container}>
            {(props.loading || props.retrievingInsights) && (
                <InsightsLoadingOverlay />
            )}
            <TrendingHazardList
                data={props.trendingHazardData}
                onPress={props.onPressTrendingHazard}
                loading={props.loading}
            />
            <div className={localClasses.pageTitleContainer}>
                <div className={localClasses.pageTitle}>
                    <Typography
                        variant={mdScreens ? 'h2' : 'h3'}
                        component="h1"
                    >
                        {props.pageTitle}
                    </Typography>
                    {props.dateRange ? (
                        <Typography variant="h4" component="h2">
                            {props.dateRange}
                        </Typography>
                    ) : props.loading || props.retrievingInsights ? (
                        <Skeleton
                            width="min(80vw, 25rem)"
                            height={theme.typography.h2.lineHeight}
                        />
                    ) : null}
                </div>
                {props.retrievingInsights ? null : (
                    <div className={localClasses.pageTitleInfoContainer}>
                        {formattedDate ? (
                            <div className={localClasses.lastUpdatedContainer}>
                                <IconButton onClick={props.onReloadPress}>
                                    <IoReload
                                        color={colors.chips.PEROLOUS}
                                        size={primaryIconSize}
                                    />
                                </IconButton>
                                <Typography variant="body1" fontWeight={500}>
                                    Last Updated: {formattedDate}
                                </Typography>
                            </div>
                        ) : null}
                        {mdScreens ? (
                            <Button onClick={props.onDownloadPress}>
                                Download Report
                            </Button>
                        ) : (
                            <IconButton onClick={onOptionsClick}>
                                <IoEllipsisVertical
                                    size={primaryIconSize}
                                    color={colors.primary.CERULEAN}
                                />
                            </IconButton>
                        )}
                    </div>
                )}
            </div>
            <div className={localClasses.insightGridContainer}>
                {!props.retrievingInsights &&
                    props.showLocalContentDisclaimer && (
                        <LocalContentDataDisclaimer />
                    )}
                <InsightGrid
                    data={props.insights}
                    quickStatRowTitle={props.quickStatRowTitle}
                />
            </div>
            <Menu
                id="query-actions"
                anchorEl={anchorEl}
                open={open}
                onClose={onOptionsClose}
                MenuListProps={{
                    'aria-labelledby': 'options-button',
                }}
            >
                <CustomMenuItem
                    icon={<IoDownload size={primaryIconSize} />}
                    label="Download Report"
                    onClick={() => {
                        props.onDownloadPress()
                        onOptionsClose()
                    }}
                />
            </Menu>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
    },
    pageTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '1.25rem 0',
        gap: '0.5rem',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    pageTitleInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 1rem 0 0.5rem',
        gap: '1rem',
        justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            width: 'calc(100% - 1.5rem)',
        },
        [theme.breakpoints.up('lg')]: {
            gap: '1.5rem',
        },
    },
    lastUpdatedContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    pageTitle: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '1rem',
    },
    insightGridContainer: {
        padding: '1rem 0',
        margin: '0 1rem',
        borderTop: `1px ${colors.grays.NOIR} solid`,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
})
