import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { IoCalendarOutline } from 'react-icons/io5'
import React, { FC } from 'react'
import { applyColorToPickerSubcomponents } from '../../lib/styles/universal'

export interface DatePickerProps {
    label?: string
    smallLabel?: boolean
    placeholder?: string
    onChange(date: Date | null): void
    value: Date | null
    selectColor?: string
    selectIcon?: React.ElementType
    iconColor?: string
    fullWidth?: boolean
    disabled?: boolean
    variableSize?: boolean
    bold?: boolean
    variant?: 'line' | 'box'
    minDate?: Date
    maxDate?: Date
    detachedLabel?: boolean
}

export const DatePicker: FC<DatePickerProps> = (props: DatePickerProps) => {
    const render = () => (
        <>
            {props.detachedLabel && (
                <Typography
                    variant={props.smallLabel ? 'h5' : 'h4'}
                    pb="0.625rem"
                    color={props.selectColor}
                    fontStyle="normal"
                    fontWeight={500}
                >
                    {props.label}
                </Typography>
            )}
            <MuiDatePicker
                {...props}
                disableFuture
                slots={{
                    openPickerIcon: () => (
                        <IoCalendarOutline color={props.iconColor} />
                    ),
                }}
                slotProps={{
                    textField: {
                        variant:
                            props.variant === 'line' ? 'standard' : 'outlined',
                        sx: {
                            ...applyColorToPickerSubcomponents(
                                props.selectColor
                            ),
                            fontWeight: props.bold ? 500 : 'normal',
                        },
                        label: props.detachedLabel ? undefined : props.label,
                        InputLabelProps: {
                            sx: {
                                fontWeight: '600',
                                color: props.selectColor || 'inherit',
                            },
                        },
                    },
                }}
            />
        </>
    )

    return props.fullWidth ? (
        <FormControl fullWidth disabled={props.disabled}>
            {render()}
        </FormControl>
    ) : (
        <Box
            sx={{
                width: props.variableSize ? 'fit-content' : '100%',
                maxWidth: props.variableSize ? undefined : 400,
                height: 'auto',
            }}
        >
            <FormControl fullWidth disabled={props.disabled}>
                {render()}
            </FormControl>
        </Box>
    )
}
