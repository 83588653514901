import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { FC, useState } from 'react'
import { IoAdd } from 'react-icons/io5'
import {
    ImageCropper,
    InsightView,
    BlockButton,
    TrendingHazardItem,
    TextWithIcon,
    ProfileLinkWithAvatar,
    GroupViewSwitch,
} from '../molecules'
import { colors } from '@hazadapt-git/public-core-base'
import ReactLogo from '../../logo.svg'
import { GroupViewType } from '../../lib/entities'
import {
    DONUT_CHART_INSIGHT,
    LINE_FILL_INSIGHT,
    LINE_GRAPH_INSIGHT,
    MULTI_BAR_GRAPH_INSIGHT,
    MULTI_COUNTER_INSIGHT,
    PRIMARY_RP_USER,
    QUICK_STAT_INSIGHT_1,
    SECONDARY_RP_USER,
    SINGLE_BAR_GRAPH_INSIGHT,
} from './data'
import { TeamMemberView } from '../molecules/TeamMemberView'
import { AddTeamMemberModal } from '../organisms'

interface MoleculesProps {}

export const Molecules: FC<MoleculesProps> = (props: MoleculesProps) => {
    // AddTeamMemberModal
    const [addMemberOpen, setAddMemberOpen] = useState<boolean>(false)

    const [cropperImage, setCropperImage] = React.useState(ReactLogo)
    const [groupView, setGroupView] = React.useState<GroupViewType>('card')
    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            padding="1rem"
        >
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    BarGraphInsightView (Single bars)
                </Typography>
                <InsightView {...SINGLE_BAR_GRAPH_INSIGHT} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    BarGraphInsightView (Stacked bars)
                </Typography>
                <InsightView {...MULTI_BAR_GRAPH_INSIGHT} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    BlockButton
                </Typography>
                <BlockButton
                    onPress={() => {}}
                    imageSrc={ReactLogo}
                    imageAlt={'React Logo'}
                    title="Upload Profile Photo"
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    DonutChartInsightView
                </Typography>
                <InsightView {...DONUT_CHART_INSIGHT} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    GroupViewSwitch
                </Typography>
                <GroupViewSwitch state={groupView} onSwitch={setGroupView} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    LineFillInsightView
                </Typography>
                <InsightView {...LINE_FILL_INSIGHT} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    LineGraphInsightView
                </Typography>
                <InsightView {...LINE_GRAPH_INSIGHT} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    MapInsightView
                </Typography>
                <Typography variant="h5">
                    See InsightGrid in Organisms below
                </Typography>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    MultiCounterInsightView
                </Typography>
                <InsightView {...MULTI_COUNTER_INSIGHT} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ProfileLinkWithAvatar
                </Typography>
                <div style={{ backgroundColor: colors.primary.CERULEAN }}>
                    <ProfileLinkWithAvatar name="Omeed" />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    QuickStatInsightView
                </Typography>
                <InsightView {...QUICK_STAT_INSIGHT_1} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TextWithIcon
                </Typography>
                <TextWithIcon icon={<IoAdd />}>Text with icon.</TextWithIcon>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ImageCropper
                </Typography>
                <ImageCropper
                    selectedImage={cropperImage}
                    onImageChange={setCropperImage}
                    showPreview
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TrendingHazardItem
                </Typography>
                <TrendingHazardItem
                    onPress={function (id: number): void {
                        throw new Error('Function not implemented.')
                    }}
                    id={0}
                    name={'Test Name for Testing'}
                    slug={''}
                    icon={{ src: ReactLogo, alt: '' }}
                    view_count={0}
                    fontShade="dark"
                    key=""
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TeamMemberView
                </Typography>
                <TeamMemberView
                    user={SECONDARY_RP_USER}
                    activeUser={SECONDARY_RP_USER}
                    groupView={groupView}
                    onSaveChangesClick={() => {}}
                    onRemoveUser={() => {}}
                    onTransferOwnership={() => {}}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    AddTeamMember
                </Typography>
                <Button
                    variant="outlined"
                    onClick={() => setAddMemberOpen(true)}
                >
                    Add Team Member
                </Button>
                <AddTeamMemberModal
                    open={addMemberOpen}
                    onClose={() => setAddMemberOpen(false)}
                    onSubmit={() => setAddMemberOpen(false)}
                    newUser={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        permissions: [],
                    }}
                    updateNewUser={() => {}}
                    activeUser={PRIMARY_RP_USER}
                />
            </Grid>
        </Grid>
    )
}
