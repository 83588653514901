import { RPUserPermission, RPUser } from '@hazadapt-git/public-core-base'

export function checkUserPermissions(
    user: RPUser | null | undefined,
    permissions: RPUserPermission[]
): boolean {
    if (!user) return false
    if (user.owner || user.admin) return true
    if (user.permissions.length === 0) return false
    return user.permissions.some((p) => permissions.includes(p))
}

export const canUserAddMembers = (user: RPUser | null | undefined): boolean =>
    checkUserPermissions(user, [
        RPUserPermission.ADD_MEMBER,
        RPUserPermission.EDIT_ORGANIZATION,
    ])

export const canUserEditMembers = (user: RPUser | null | undefined): boolean =>
    checkUserPermissions(user, [
        RPUserPermission.EDIT_ORGANIZATION,
        RPUserPermission.EDIT_MEMBER,
    ])

export const canUserManageMembers = (
    user: RPUser | null | undefined
): boolean =>
    checkUserPermissions(user, [
        RPUserPermission.ADD_MEMBER,
        RPUserPermission.EDIT_ORGANIZATION,
        RPUserPermission.EDIT_MEMBER,
        RPUserPermission.DELETE_MEMBER,
    ])

export const canUserDeleteMembers = (
    user: RPUser | null | undefined
): boolean =>
    checkUserPermissions(user, [
        RPUserPermission.EDIT_ORGANIZATION,
        RPUserPermission.DELETE_MEMBER,
    ])

export const canUserEditOrganization = (
    user: RPUser | null | undefined
): boolean => checkUserPermissions(user, [RPUserPermission.EDIT_ORGANIZATION])

export const isUserAdmin = (user: RPUser | null | undefined): boolean =>
    checkUserPermissions(user, [RPUserPermission.ADMIN])

export const isUserOwner = (user: RPUser | null | undefined): boolean =>
    !!user?.owner
