import React, { CSSProperties, FC } from 'react'

import RPLogoSVG from '../../assets/images/RP-Logo.svg'
import { useStyles } from '../../lib/styles/universal'

interface RPLogoProps {
    style?: CSSProperties
    className?: string
    onClick?: React.MouseEventHandler
}

export const RPLogo: FC<RPLogoProps> = (props: RPLogoProps) => {
    const { classes } = useStyles()
    const renderLogo = () => (
        <img
            {...props}
            src={RPLogoSVG}
            alt="ResiliencePoint application logo"
        />
    )
    return props.onClick ? (
        <button className={classes.unstyledButton} onClick={props.onClick}>
            {renderLogo()}
        </button>
    ) : (
        renderLogo()
    )
}
