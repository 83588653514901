import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { LoadingView, OrganizationDetailsTemplate } from '../components'
import { NamespacedPageProps, NewRPUser } from '../lib/entities'
import {
    colors,
    isEqual,
    OrganizationWithMembers,
} from '@hazadapt-git/public-core-base'
import { inviteUser, toast, updateOrganization } from '../lib/utils'
import { useNavigate } from 'react-router'
import {
    errorColor,
    primaryIconSize,
    successColor,
} from '../lib/styles/universal'
import {
    IoAlertCircle,
    IoCheckmarkCircle,
    IoCloudUploadOutline,
} from 'react-icons/io5'
import { AxiosError } from 'axios'
import { useOrganization } from '../lib/hooks/useOrganization'

interface OrganizationDetailsProps extends NamespacedPageProps {}

const SetupOrganizationPage: FC<OrganizationDetailsProps> = ({ user }) => {
    const navigate = useNavigate()
    const orgRef = useRef<OrganizationWithMembers | null>(null)

    const [pendingInvitations, setPendingInvitations] = useState<NewRPUser[]>(
        []
    )
    const [changes, setChanges] = useState<boolean>(false)

    const {
        organization,
        setOrganization,
        handleSaveLogo,
        handleSaveUserChanges,
        handleRemoveUser,
        handleTransferOwnership,
    } = useOrganization()

    useEffect(() => {
        document.title = 'Setup Organization - ResiliencePoint'
    }, [])

    useEffect(() => {
        orgRef.current = organization ?? null
    }, [organization])

    useEffect(() => {
        const checkForChanges = () => {
            if (
                pendingInvitations.length > 0 ||
                isEqual(organization, orgRef.current) === false
            ) {
                setChanges(true)
            } else {
                setChanges(false)
            }
        }
        checkForChanges()
    }, [pendingInvitations, organization, orgRef])

    const onConfirmSkip = useCallback(async () => {
        if (!orgRef.current) return
        updateOrganization({
            ...orgRef.current,
            status: 'active',
        })
            .then(() => {
                navigate('/')
            })
            .catch((err: AxiosError) => {
                console.error(err)
                toast(
                    err.message,
                    <IoAlertCircle size={primaryIconSize} color={errorColor} />
                )
            })
    }, [navigate, orgRef])

    const onDoneClick = useCallback(async () => {
        if (!organization) return
        if (!organization.logo) {
            toast(
                `Organization logo is required, please add one to continue.`,
                <IoAlertCircle size={primaryIconSize} color={errorColor} />
            )
            return
        }

        toast(
            'Saving...',
            <IoCloudUploadOutline
                color={colors.primary.BLUEBERRY}
                size={primaryIconSize}
            />
        )
        try {
            const members = await inviteUser([...pendingInvitations])
            const updatedOrg = await updateOrganization({
                ...organization,
                status: 'active',
                members,
            })
            setOrganization(updatedOrg)
            toast(
                `You've finished setting up your organization! Let's go!`,
                <IoCheckmarkCircle
                    size={primaryIconSize}
                    color={successColor}
                />
            )
            navigate('/')
        } catch (err) {
            const error = err as AxiosError
            console.error(error)
            toast(
                error.message,
                <IoAlertCircle size={primaryIconSize} color={errorColor} />
            )
        }
    }, [organization, navigate, pendingInvitations, setOrganization])

    const onSubmitNewUser = (newUser: NewRPUser) => {
        if (!organization) return

        setPendingInvitations([...pendingInvitations, newUser])
    }

    return user && organization ? (
        <OrganizationDetailsTemplate
            activeUser={user}
            organization={organization}
            mode="setup"
            onSaveLogoClick={handleSaveLogo}
            onDoneClick={onDoneClick}
            onConfirmSkip={onConfirmSkip}
            onSubmitNewUser={onSubmitNewUser}
            onRemoveUser={handleRemoveUser}
            onTransferOwnership={handleTransferOwnership}
            onSaveUserChanges={handleSaveUserChanges}
            pendingChanges={changes}
        />
    ) : user === null || organization === null ? null : (
        <LoadingView />
    )
}

export default SetupOrganizationPage
