import {
    AddOn,
    GetRPOrganizationsResponse,
    GetSubscriptionsDTO,
    InsightQueryHistory,
    InsightQueryType,
    Organization,
    OrganizationChangelogDTO,
    OrganizationInviteDTO,
    OrganizationOverview,
    OrganizationWithMembers,
    GroupedPickerOptions,
    RPUser,
    UpdateOrganizationDTO,
    colors,
} from '@hazadapt-git/public-core-base'
import { AxiosError, AxiosResponse } from 'axios'
import { isa } from '../api'
import { toast } from './misc'
import { NewRPUser } from '../entities'
import { store } from '../store'
import { setOrganization } from '../slices/base'
import { setAddons, setSubscriptions } from '../slices/payment'
import { getEnvVars } from '../config'
import {
    IoAlertCircle,
    IoCheckmark,
    IoCloudUploadOutline,
} from 'react-icons/io5'
import { errorColor, primaryIconSize, successColor } from '../styles/universal'

const { apiUrl } = getEnvVars()

export const getSplashOrgDetails = async (): Promise<OrganizationOverview> => {
    const response: AxiosResponse<Organization> = await isa.get('/splash')
    return response.data
}

export const getOrganization = async (): Promise<Organization> => {
    const response: AxiosResponse<Organization> = await isa.get(
        '/organization?overview=true'
    )
    return response.data
}

export const getFullOrganization =
    async (): Promise<OrganizationWithMembers> => {
        const response: AxiosResponse<OrganizationWithMembers> = await isa.get(
            '/organization'
        )
        return response.data
    }

export const getOrganizations =
    async (): Promise<GetRPOrganizationsResponse> => {
        const response: AxiosResponse<GetRPOrganizationsResponse> =
            await isa.get('/organizations')
        return response.data
    }

export const updateOrganization = async (
    org: OrganizationWithMembers
): Promise<OrganizationWithMembers> => {
    const { members, ...organization } = org
    const response: AxiosResponse<OrganizationWithMembers> = await isa.put<
        any,
        AxiosResponse<OrganizationWithMembers>,
        UpdateOrganizationDTO
    >('/organization', { organization, members })
    store.dispatch(setOrganization({ ...response.data }))
    return response.data
}

/**
 * Uploads the user's profile picture to S3
 * @param image the profile picture file
 * @returns a signed URL for the profile picture
 */
export const updateOrganizationLogo = async (
    image: File
): Promise<OrganizationWithMembers> => {
    try {
        toast(
            'Saving...',
            <IoCloudUploadOutline
                color={colors.primary.BLUEBERRY}
                size={primaryIconSize}
            />
        )
        const formData = new FormData()
        formData.append('logo', image)
        const response: AxiosResponse<OrganizationWithMembers> = await isa.put(
            '/organization',
            formData,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            }
        )
        store.dispatch(setOrganization({ ...response.data }))
        toast(
            'Logo updated!',
            <IoCheckmark size={primaryIconSize} color={successColor} />
        )
        return response.data
    } catch (err) {
        toast(
            'There was an error uploading your logo. Please try again.',
            <IoAlertCircle color={errorColor} size={primaryIconSize} />
        )
        throw err
    }
}

export const transferOrgOwnership = async (
    user_id: number
): Promise<RPUser[]> => {
    const response: AxiosResponse<RPUser[]> = await isa.put(
        `/organization/transfer-ownership`,
        { member_id: user_id }
    )
    return response.data
}

export const inviteUser = async (newUser: NewRPUser[]): Promise<RPUser[]> => {
    const response: AxiosResponse<RPUser[]> = await isa.post('/member', newUser)
    return response.data
}

export const removeUser = async (userIds: number[]): Promise<RPUser[]> => {
    const response: AxiosResponse<RPUser[]> = await isa.delete(
        `/member?user_id=${userIds.join(',')}`
    )
    return response.data
}

export const getInvite = async (
    inviteCode: string
): Promise<OrganizationInviteDTO> => {
    const response: AxiosResponse<OrganizationInviteDTO> = await isa.get(
        `/invite/${inviteCode}`
    )
    return response.data
}

export const getSavedQueries = async (
    page = 1,
    queryType?: InsightQueryType
): Promise<InsightQueryHistory> => {
    const url = new URL(`${isa.defaults.baseURL ?? apiUrl}/queries`)
    url.searchParams.append('page', page.toString())
    if (queryType) url.searchParams.append('query_type', queryType)
    const response: AxiosResponse<InsightQueryHistory> = await isa.get(
        url.toString()
    )
    return response.data
}

export const getOrganizationChangelog = async (
    page = 1
): Promise<OrganizationChangelogDTO> => {
    const response: AxiosResponse<OrganizationChangelogDTO> = await isa.get(
        `/changelog/organization?page=${page}`
    )
    return response.data
}

export const getAvailableAddons = async (): Promise<void> => {
    try {
        const response: AxiosResponse<AddOn[]> = await isa.get('/add-ons')
        store.dispatch(setAddons(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const getSubscriptions = async (): Promise<void> => {
    try {
        const response: AxiosResponse<GetSubscriptionsDTO> = await isa.get(
            '/subscription'
        )
        store.dispatch(setSubscriptions(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const cancelSubscription = async (
    subscription_id: string
): Promise<void> => {
    try {
        const response: AxiosResponse<GetSubscriptionsDTO> = await isa.post(
            `/subscription/${subscription_id}/cancel`
        )
        store.dispatch(setSubscriptions(response.data))
    } catch (err) {
        const error = err as AxiosError
        throw error
    }
}

export const deletePaymentMethod = async (
    payment_method_id: string
): Promise<void> => {
    try {
        const response: AxiosResponse<GetSubscriptionsDTO> = await isa.delete(
            `/payment-method/${payment_method_id}`
        )
        store.dispatch(setSubscriptions(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const buildAreaPickerOptions = (
    organization: Organization,
    selectedAreas: string[] = []
): GroupedPickerOptions<string>[] => {
    const hasCounties = selectedAreas.some((a) =>
        organization.areas.county.includes(a)
    )
    const hasZips = selectedAreas.some((a) =>
        organization.areas.zip.includes(a)
    )
    console.log(hasCounties, hasZips)
    const zips = [...organization.areas.zip]
    const counties = [...organization.areas.county]
    const states = [...organization.areas.state]

    const sortSelectedToFront = (a: string, b: string) => {
        if (selectedAreas.includes(a) && !selectedAreas.includes(b)) return -1
        if (selectedAreas.includes(b) && !selectedAreas.includes(a)) return 1
        return a < b ? -1 : 1
    }
    zips.sort(sortSelectedToFront)
    counties.sort((a, b) => {
        if (selectedAreas.includes(a) && !selectedAreas.includes(b)) return -1
        if (selectedAreas.includes(b) && !selectedAreas.includes(a)) return 1
        const aPieces = a.split(', ')
        const bPieces = b.split(', ')
        const aState = aPieces[aPieces.length - 1]
        const bState = bPieces[bPieces.length - 1]
        if (aState !== bState) return aState < bState ? -1 : 1 // Sort by state
        return a < b ? -1 : 1 // Same state; sort by county
    })
    states.sort(sortSelectedToFront)
    const zipOptions: GroupedPickerOptions<string> = {
        id: 'zips',
        label: 'ZIP Codes',
        options: zips.map((z) => ({
            label: `Zip code ${z}`,
            value: z,
        })),
    }
    const countyOptions: GroupedPickerOptions<string> = {
        id: 'counties',
        label: 'Counties',
        options: counties.map((c) => ({
            label: c,
            value: c,
        })),
    }
    const stateOptions: GroupedPickerOptions<string> = {
        id: 'states',
        label: 'States',
        options: states.map((s) => ({
            label: s,
            value: s,
        })),
    }
    const groups: GroupedPickerOptions<string>[] = []
    if (hasZips) {
        groups.push(zipOptions, stateOptions, countyOptions)
    } else if (hasCounties) {
        groups.push(countyOptions, stateOptions, zipOptions)
    } else {
        groups.push(stateOptions, countyOptions, zipOptions)
    }

    // Only include groups with at least one option
    const options = groups.filter((g) => g.options.length > 0)
    return options
}

export const getAvailableOrganizationAreas = (
    groups: GroupedPickerOptions<string>[]
): [string[], string[], string[]] => {
    const availableZips = (
        groups.find((g) => g.id === 'zips')?.options ?? []
    ).map((o) => o.value)
    const availableCounties = (
        groups.find((g) => g.id === 'counties')?.options ?? []
    ).map((o) => o.value)
    const availableStates = (
        groups.find((g) => g.id === 'states')?.options ?? []
    ).map((o) => o.value)
    return [availableZips, availableCounties, availableStates]
}

export const sortOrgMembers = (a: RPUser, b: RPUser): number => {
    if (a.status === 'invited') return 1
    if (b.status === 'invited') return -1
    if (a.owner) return -1
    if (b.owner) return 1
    if (a.admin && !b.admin) return -1
    if (b.admin && !a.admin) return 1
    if (a.date_created && b.date_created) {
        return a.date_created < b.date_created ? -1 : 1
    } else {
        return a.last_name < b.last_name ? -1 : 1
    }
}
