import React, { FC } from 'react'
import { RPUser } from '@hazadapt-git/public-core-base'
import { Dialog } from '../atoms'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

interface TeamMemberDeleteModalProps {
    user: RPUser
    open?: boolean
    onClose(): void
    onRemoveUser(user: RPUser): void
}

export const TeamMemberDeleteModal: FC<TeamMemberDeleteModalProps> = ({
    user,
    open,
    onClose,
    onRemoveUser,
}) => {
    const onDeleteUser = async (user: RPUser) => {
        onRemoveUser(user)
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title="Remove Team Member"
            content={
                <div>
                    <Typography variant="h5">
                        Are you sure you want to remove {user.first_name}{' '}
                        {user.last_name} from your organization? You can always
                        add them back later.
                    </Typography>
                </div>
            }
            ctas={[
                <Button
                    key="remove-from-organization"
                    onClick={() => {
                        onDeleteUser(user)
                    }}
                    variant="contained"
                    color="error"
                >
                    Remove from Organization
                </Button>,
                //! When Pause User Functionality is added, uncomment this
                // <Button onClick={handleClose} variant="outlined">
                //     Pause Org Access
                // </Button>,
            ]}
        />
    )
}
