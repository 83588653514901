import React, { FC } from 'react'
import { NamespacedPageProps } from '../lib/entities'
import { getReceipt } from '../lib/utils'
import { CheckoutSuccessPageTemplate, LoadingView } from '../components'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Transaction } from '@hazadapt-git/public-core-base'

interface CheckoutSuccessPageProps extends NamespacedPageProps {}

export const CheckoutSuccessPage: FC<CheckoutSuccessPageProps> = ({
    loading,
    user,
    organization,
}: CheckoutSuccessPageProps) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const [transaction, setTransaction] = React.useState<Transaction>()
    const payment_intent = searchParams.get('payment_intent')

    React.useEffect(() => {
        document.title = 'Checkout Success - ResiliencePoint'
    }, [])

    React.useEffect(() => {
        if (payment_intent) {
            getReceipt(payment_intent).then(setTransaction).catch(console.error)
        } else {
            navigate('/')
        }
    }, [payment_intent, navigate])

    return transaction && !loading && user && organization ? (
        <CheckoutSuccessPageTemplate
            email={user.email}
            transaction={transaction}
            hideReturnToDashboard={organization.status !== 'active'}
        />
    ) : (
        <LoadingView />
    )
}
