import React, { FC } from 'react'

import Typography from '@mui/material/Typography'

interface AppVersionNumberProps {}

export const AppVersionNumber: FC<AppVersionNumberProps> = (
    props: AppVersionNumberProps
) => {
    return (
        <Typography fontSize="1.125rem" textAlign="center" fontWeight={500}>
            Version {process.env.REACT_APP_VERSION}
        </Typography>
    )
}
