import { colors } from '@hazadapt-git/public-core-base'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import { Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { FC } from 'react'

interface ImageWithAccessoryProps {
    src?: string
    badgeContent: React.ReactNode
    badgeStyles?: SxProps<Theme>
    avatarStyles?: SxProps<Theme>
}

export const ImageWithAccessory: FC<ImageWithAccessoryProps> = (
    props: ImageWithAccessoryProps
) => {
    return (
        <Badge
            overlap="circular"
            color="default"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={props.badgeContent}
            sx={props.badgeStyles ?? badgeStyle}
        >
            <Avatar sx={props.avatarStyles} src={props.src} />
        </Badge>
    )
}

const badgeStyle: SxProps<Theme> = {
    height: 'min-content',
    '& .MuiBadge-badge': {
        color: colors.grays.NOIR,
        backgroundColor: colors.grays.SMOOTH,
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: 25,
        boxShadow: `0 0.125rem 0.25rem ${colors.grays.NIMBUS}`,
    },
}
