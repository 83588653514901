import { FC } from 'react'
import {
    HorizontalScroller,
    QuickStatInsightView,
    QuickStatInsightViewProps,
} from '../molecules'
import Typography from '@mui/material/Typography'

interface QuickStatInsightListProps {
    data: QuickStatInsightViewProps[]
    title?: string
}

export const QuickStatInsightList: FC<QuickStatInsightListProps> = ({
    title,
    data: insights,
}) => {
    if (insights.length === 0) return null
    return (
        <div
            style={{
                overflowX: 'auto',
            }}
        >
            {title && (
                <Typography variant="h4" mb="0.5rem">
                    {title}
                </Typography>
            )}
            <HorizontalScroller>
                {insights.map((insight) => (
                    <QuickStatInsightView
                        {...insight}
                        key={insight.placement_id}
                    />
                ))}
            </HorizontalScroller>
        </div>
    )
}
