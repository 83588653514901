import Typography from '@mui/material/Typography'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'

interface ImageEditorDisclaimerProps {}

export const ImageEditorDisclaimer: FC<ImageEditorDisclaimerProps> = (
    props: ImageEditorDisclaimerProps
) => {
    const { classes: localClasses } = useLocalStyles()

    return (
        <div className={localClasses.imageEditorDisclaimer}>
            <Typography fontWeight="bold">Please Note:</Typography>
            <Typography>
                Profile images and logos used should be work appropriate. Any
                Images depicting acts of violence, racism, sexism, homophobia or
                bigotry are expressly prohibited.
            </Typography>
            <Typography>
                Any entity found to be in violation of this policy will be
                subject to review, restriction and removal from ResiliencePoint.
            </Typography>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    imageEditorDisclaimer: {
        marginTop: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
})
