import { colors } from '@hazadapt-git/public-core-base'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import HazAdaptLogo from './../../assets/images/HazAdaptLogoRW.svg'

interface SiteFooterProps {}

export const SiteFooter: FC<SiteFooterProps> = (props: SiteFooterProps) => {
    const { classes: localClasses } = useLocalStyles()

    return (
        <div className={localClasses.container}>
            <div className={localClasses.text}>
                <Typography
                    variant="h4"
                    style={{
                        color: colors.grays.BLANC,
                        paddingRight: '1rem',
                    }}
                >
                    Powered By
                </Typography>
                <Link href="https://hazadapt.com">
                    <img
                        src={HazAdaptLogo}
                        alt={'HazAdapt Logo'}
                        className={localClasses.companyLogo}
                    />
                </Link>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        backgroundColor: colors.primary.CERULEAN,
        padding: '1rem',
    },
    text: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    companyLogo: {
        height: '2.5rem',
        width: 'auto',
    },
})
