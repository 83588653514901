import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { NamespacedPageProps, RPPaymentMethod } from '../../lib/entities'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
    customHazTealColor,
    primaryIconSize,
    theme,
    useStyles,
} from '../../lib/styles/universal'
import { useNavigate } from 'react-router-dom'
import {
    IoAddCircleOutline,
    IoOpenOutline,
    IoTrashOutline,
} from 'react-icons/io5'
import { colors } from '@hazadapt-git/public-core-base'
import classNames from 'classnames'
import { useAppSelector } from '../../lib/store'
import {
    deletePaymentMethod,
    getHazardData,
    useWindowSizeUp,
} from '../../lib/utils'
import { useSubscription } from '../../lib/hooks/useSubscription'
import { AddSeatsTriggerAndDrawer } from './AddSeatsTriggerAndDrawer'
import { PaymentMethodDetails } from '../molecules'
import { Dialog } from '../atoms'

interface SubscriptionsOverviewProps extends NamespacedPageProps {}

export const SubscriptionsOverview: FC<SubscriptionsOverviewProps> = ({
    organization,
}) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const containerRef = useRef(null)
    const lgElement = useWindowSizeUp('lg')
    const navigate = useNavigate()
    const { hazards } = useAppSelector((state) => state.content)
    const {
        rootSubscription,
        rootSubscriptionEndDate,
        simplifiedSeatSubscriptions,
        paymentMethods,
    } = useSubscription()
    const [
        showConfirmDeletePaymentMethodModal,
        setShowConfirmDeletePaymentMethodModal,
    ] = useState(false)
    const [paymentMethodToDelete, setPaymentMethodToDelete] =
        useState<RPPaymentMethod>()

    useEffect(() => {
        getHazardData()
    }, [organization])

    const visibleHazards = useMemo(() => {
        return hazards
            .filter((haz) => organization?.visible_hazards.includes(haz.id))
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [hazards, organization])

    const activeSeatSubscriptions = simplifiedSeatSubscriptions?.filter(
        (sub) => sub.status === 'active'
    )

    const onDeletePaymentMethodClick = useCallback(
        (e: React.MouseEvent, method: RPPaymentMethod) => {
            setShowConfirmDeletePaymentMethodModal(true)
            setPaymentMethodToDelete(method)
        },
        []
    )

    const closeDeletePaymentMethodModal = useCallback(() => {
        setShowConfirmDeletePaymentMethodModal(false)
        setPaymentMethodToDelete(undefined)
    }, [])

    const confirmDeletePaymentMethod: React.MouseEventHandler = useCallback(
        async (e) => {
            if (paymentMethodToDelete) {
                closeDeletePaymentMethodModal()
                try {
                    await deletePaymentMethod(paymentMethodToDelete.id)
                } catch (err) {
                    console.error('Failed to delete payment method:', err)
                }
            }
        },
        [closeDeletePaymentMethodModal, paymentMethodToDelete]
    )

    const handleAddNewPaymentMethod: React.MouseEventHandler = useCallback(
        (e) => {
            navigate('/payment')
        },
        [navigate]
    )

    return (
        <>
            <Stack gap={'2rem'} paddingBottom={'4rem'} ref={containerRef}>
                {/* Page Description */}
                <Stack gap={'2rem'} maxWidth={'43rem'}>
                    <Typography
                        variant={lgElement ? 'h2' : 'h3'}
                        component="h1"
                    >
                        Subscriptions/Billing
                    </Typography>
                    <Typography variant="body2" color={customHazTealColor}>
                        Please allow up to 24 hours for changes to your
                        ResiliencePoint subscription to take effect. Changes to
                        your subscription do not affect any extra seats that
                        have been purchased. To make changes to any seats
                        outside of the subscription, please do so from the{' '}
                        <Link href="subscriptions/seats" target="_blank">
                            Seats Page <IoOpenOutline />
                        </Link>
                    </Typography>
                </Stack>

                {/* Payment Method */}
                <Stack gap={'1rem'}>
                    <Typography variant="h4">Saved Payment Methods</Typography>
                    <div className={localClasses.paymentMethodsWrapper}>
                        <div className={localClasses.paymentMethods}>
                            {paymentMethods.length > 0 ? (
                                paymentMethods.map((pm) => (
                                    <div className={localClasses.paymentMethod}>
                                        <PaymentMethodDetails
                                            {...pm}
                                            actions={[
                                                {
                                                    label: 'Delete Payment Method',
                                                    icon: (
                                                        <IoTrashOutline
                                                            size={
                                                                primaryIconSize
                                                            }
                                                        />
                                                    ),
                                                    onClick: (e) =>
                                                        onDeletePaymentMethodClick(
                                                            e,
                                                            pm
                                                        ),
                                                },
                                            ]}
                                        />
                                    </div>
                                ))
                            ) : (
                                <Typography variant="subtitle2">
                                    No saved payment method
                                </Typography>
                            )}
                            <button
                                className={classNames(
                                    classes.unstyledButton,
                                    localClasses.paymentMethod,
                                    localClasses.addNewPaymentMethod
                                )}
                                onClick={handleAddNewPaymentMethod}
                            >
                                <Stack
                                    alignItems="center"
                                    gap="0.5rem"
                                    justifyContent="center"
                                >
                                    <IoAddCircleOutline
                                        size="2rem"
                                        color={colors.grays.NOIR}
                                    />
                                    <Typography color={colors.grays.CHARCOAL}>
                                        Add New Payment Method
                                    </Typography>
                                </Stack>
                            </button>
                        </div>
                    </div>
                </Stack>

                {/* Subscriptions */}
                <Stack gap={'1rem'}>
                    <Typography variant="h4">Current Subscription</Typography>
                    <Stack
                        direction={lgElement ? 'row' : 'column'}
                        gap={'1rem'}
                    >
                        {/* Root Subscription */}
                        <Stack
                            className={classNames(
                                localClasses.borderedContainer,
                                localClasses.subscriptionItemContainer
                            )}
                        >
                            <div
                                className={localClasses.renewalDate}
                                style={{
                                    backgroundColor:
                                        rootSubscription?.metadata.status ===
                                        'active'
                                            ? colors.primary.CERULEAN
                                            : colors.secondary.HEAT_WAVE,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    color={colors.grays.BLANC}
                                >
                                    {rootSubscription?.metadata.status ===
                                    'active'
                                        ? 'Renews'
                                        : 'Expires'}{' '}
                                    On: {rootSubscriptionEndDate}
                                </Typography>
                            </div>
                            <Typography
                                variant={lgElement ? 'h1' : 'h2'}
                                paddingBottom={'1rem'}
                            >
                                ResiliencePoint
                            </Typography>
                            <Typography variant="h4">
                                Hazard Guide Insights:
                            </Typography>
                            <div className={localClasses.subscriptionItemList}>
                                {visibleHazards.length === 0 ? (
                                    <Typography
                                        variant={lgElement ? 'h4' : 'body1'}
                                        fontWeight={500}
                                    >
                                        (All Hazards)
                                    </Typography>
                                ) : (
                                    visibleHazards.map((haz) => (
                                        <Typography
                                            key={haz.id}
                                            variant={lgElement ? 'h4' : 'body1'}
                                            fontWeight={500}
                                        >
                                            {haz.name}
                                        </Typography>
                                    ))
                                )}
                            </div>
                            {/* //TODO */}
                            {/* <Typography variant="h4">
                                Local Safety Information
                            </Typography> */}
                            {rootSubscription?.metadata.status === 'active' && (
                                <Button
                                    variant="outlined"
                                    onClick={() => navigate('core/cancel')}
                                    color="error"
                                    sx={{ alignSelf: 'end' }}
                                >
                                    Cancel Subscription
                                </Button>
                            )}
                        </Stack>
                        {/* Add-On Subscriptions */}
                        <Stack
                            className={classNames(
                                localClasses.borderedContainer,
                                localClasses.subscriptionItemContainer
                            )}
                        >
                            <Typography
                                variant={lgElement ? 'h1' : 'h2'}
                                paddingTop={'2.1rem'}
                            >
                                Add-Ons
                            </Typography>
                            {/* Seat Subscriptions */}
                            <div className={localClasses.subscriptionItemList}>
                                {activeSeatSubscriptions.length > 0 ? (
                                    <ul style={{ margin: 0 }}>
                                        {activeSeatSubscriptions.map((item) => (
                                            <li key={item.id}>
                                                <Typography
                                                    variant={
                                                        lgElement
                                                            ? 'h4'
                                                            : 'body1'
                                                    }
                                                    fontWeight={500}
                                                >
                                                    x{item.seatCount} seats -
                                                    Renewal: {item.renewalDate}
                                                </Typography>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Typography variant="h4">
                                        No Add-Ons to display
                                    </Typography>
                                )}
                            </div>
                            <div style={{ alignSelf: 'end' }}>
                                {activeSeatSubscriptions.length === 0 ? (
                                    <AddSeatsTriggerAndDrawer buttonText="Add Seats" />
                                ) : (
                                    <Button onClick={() => navigate('seats')}>
                                        Manage
                                    </Button>
                                )}
                            </div>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {showConfirmDeletePaymentMethodModal && paymentMethodToDelete && (
                <Dialog
                    open={showConfirmDeletePaymentMethodModal}
                    onClose={closeDeletePaymentMethodModal}
                    title="Delete Payment Method"
                    content={
                        <Stack gap="1rem">
                            <Typography>
                                Are you sure you want to delete the following
                                payment method from your organization's
                                ResiliencePoint space?
                            </Typography>
                            <PaymentMethodDetails {...paymentMethodToDelete} />
                            <Typography>
                                You can always add it again later.
                            </Typography>
                        </Stack>
                    }
                    ctas={[
                        <Button
                            key="cancel"
                            onClick={closeDeletePaymentMethodModal}
                            variant="outlined"
                            color="secondary"
                        >
                            Cancel
                        </Button>,
                        <Button
                            key="confirm-delete"
                            onClick={confirmDeletePaymentMethod}
                            variant="contained"
                            color="error"
                        >
                            Delete Payment Method
                        </Button>,
                    ]}
                />
            )}
        </>
    )
}

const useLocalStyles = makeStyles()({
    borderedContainer: {
        backgroundColor: colors.grays.BLANC,
        border: '1px solid',
        borderRadius: '.625rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
    },
    paymentMethodsWrapper: {
        alignItems: 'center',
    },
    paymentMethods: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        gap: '1rem',
    },
    paymentMethod: {
        padding: '1rem 2rem',
        paddingRight: '3rem',
        borderRadius: '0.5rem',
        border: '1px solid',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    addNewPaymentMethod: {
        paddingRight: '2rem !important',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.grays.SILK,
        textAlign: 'center',
    },
    subscriptionItemContainer: {
        flex: 1,
        justifyContent: 'space-between',
        padding: '1.5rem',
        position: 'relative',
    },
    subscriptionItemList: {
        height: '4rem',
        overflow: 'auto',
        margin: '.5rem 0',
    },
    renewalDate: {
        alignSelf: 'end',
        borderRadius: '.625rem',
        padding: '.5rem 1rem',
        width: 'auto',
    },
})
