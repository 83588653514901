import { FC, useEffect, useState } from 'react'
import { Navigate, Route, RouteProps, Routes, RoutesProps } from 'react-router'
import { useStyles } from '../lib/styles/universal'
import { ScrollToTop, SiteFooter, SiteHeader } from '../components'
import { RootWelcomePage } from '../pages'
import {
    getOrganizations,
    getPublicProfilePicture,
    getPublicUser,
} from '../lib/utils'
import {
    GetRPOrganizationsResponse,
    User,
} from '@hazadapt-git/public-core-base'

export const RootRouter: FC<RoutesProps> = (props: RoutesProps) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [getOrgResponse, setGetOrgResponse] =
        useState<GetRPOrganizationsResponse>()
    const [publicUser, setPublicUser] = useState<User>()
    const [publicPfp, setPublicPfp] = useState<string>()

    const { classes } = useStyles()

    useEffect(() => {
        const pubUserPromise = getPublicUser()
            .then(setPublicUser)
            .catch((err) => {
                console.error(err)
            })
        const orgPromise = getOrganizations()
            .then(setGetOrgResponse)
            .catch((err) => {
                console.error(err)
            })
        const pubProfilePicturePromise = getPublicProfilePicture()
            .then(setPublicPfp)
            .catch((err) => {
                console.error(err)
            })
        Promise.all([
            pubUserPromise,
            orgPromise,
            pubProfilePicturePromise,
        ]).finally(() => setLoading(false))
    }, [])

    const routes: RouteProps[] = [
        {
            path: '/',
            element: (
                <RootWelcomePage
                    loading={loading}
                    orgResponse={getOrgResponse ?? undefined}
                    publicUser={publicUser ?? undefined}
                />
            ),
        },
        // Catch-all; keep this as the last route in the array
        {
            path: '*',
            element: <Navigate replace to="/" />,
        },
    ]

    return (
        <ScrollToTop>
            <div className={classes.root}>
                <SiteHeader
                    publicUser={publicUser}
                    publicProfilePicture={publicPfp}
                    hideHamburgerMenu
                />
                <div className={classes.page}>
                    <Routes {...props}>
                        {routes.map((route: RouteProps) => (
                            <Route key={route.path} {...route} />
                        ))}
                    </Routes>
                </div>
                <SiteFooter />
            </div>
        </ScrollToTop>
    )
}
