import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import { IoSettingsSharp } from 'react-icons/io5'
import { primaryIconSize } from '../../lib/styles/universal'
import { colors } from '@hazadapt-git/public-core-base'
import { MenuItemProps } from '../../lib/entities'
import { CustomMenuItem } from '../../lib/entities/misc'

interface SettingsOptionsProps {
    options: MenuItemProps[]
}

export const SettingsOptions: FC<SettingsOptionsProps> = (
    props: SettingsOptionsProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)

    const onSettingsPress = (e: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) setAnchorEl(null)
        else setAnchorEl(e.currentTarget)
    }
    const onClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton
                className={localClasses.container}
                onClick={onSettingsPress}
            >
                <IoSettingsSharp
                    color={colors.grays.BLANC}
                    size={primaryIconSize}
                />
            </IconButton>
            {props.options.length > 0 && (
                <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
                    {props.options.map((o, i) => (
                        <CustomMenuItem
                            {...o}
                            onClick={(e) => {
                                o.onClick?.(e)
                                onClose()
                            }}
                        />
                    ))}
                </Menu>
            )}
        </>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        border: `1px ${colors.grays.BLANC} solid`,
        width: '2.5rem',
        height: '2.5rem',
        padding: 0,
    },
})
