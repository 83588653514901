import React, { FC, useEffect } from 'react'

import { LoadingView, OrganizationDetailsTemplate } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { RPUserPermission } from '@hazadapt-git/public-core-base'
import { checkUserPermissions } from '../lib/utils'
import { Navigate, Route, Routes, useNavigate } from 'react-router'
import { OrganizationChangelogPage } from './OrganizationChangelog'
import { useOrganization } from '../lib/hooks/useOrganization'

interface OrganizationDetailsProps extends NamespacedPageProps {}

export const ManageOrganizationPage: FC<OrganizationDetailsProps> = ({
    user,
    loading,
}) => {
    const navigate = useNavigate()
    const {
        organization,
        handleSaveLogo,
        handleResendInvite,
        handleSaveUserChanges,
        handleRemoveUser,
        handleTransferOwnership,
        handleSubmitNewUser,
    } = useOrganization()

    useEffect(() => {
        document.title = checkUserPermissions(user, [
            RPUserPermission.EDIT_ORGANIZATION,
        ])
            ? 'Organization Settings - ResiliencePoint'
            : 'My Organization - ResiliencePoint'
    }, [user])

    const onGoToOrgChangelog = () =>
        navigate('/settings/organization/changelog')

    return user && organization ? (
        <Routes>
            <Route
                path=""
                element={
                    <OrganizationDetailsTemplate
                        activeUser={user}
                        organization={organization}
                        mode="manage"
                        onSaveLogoClick={handleSaveLogo}
                        onSubmitNewUser={handleSubmitNewUser}
                        onResendInvite={handleResendInvite}
                        onRemoveUser={handleRemoveUser}
                        onTransferOwnership={handleTransferOwnership}
                        onSaveUserChanges={handleSaveUserChanges}
                        onGoToOrgChangelog={onGoToOrgChangelog}
                    />
                }
            />
            <Route
                path="changelog"
                element={
                    <OrganizationChangelogPage
                        user={user}
                        organization={organization}
                        loading={loading}
                    />
                }
            />
            <Route
                path="*"
                element={<Navigate replace to="/settings/organization" />}
            />
        </Routes>
    ) : !loading && (user === null || organization === null) ? null : (
        <LoadingView />
    )
}
