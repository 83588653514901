import React, { FC } from 'react'
import { Dialog, TextField } from '../atoms'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { RPUser, RPUserPermission } from '@hazadapt-git/public-core-base'
import { makeStyles } from 'tss-react/mui'
import { format } from 'date-fns-tz'
import { PermissionsList } from './PermissionsList'
import { Formik, Form } from 'formik'

interface TeamMemberConfigModalProps {
    user: RPUser
    activeUser: RPUser
    onSaveChanges(updatedUser: RPUser): void
    // Dialog props
    open?: boolean
    onClose(): void
    mode: 'email' | 'permissions'
}

export const TeamMemberConfigModal: FC<TeamMemberConfigModalProps> = (
    props: TeamMemberConfigModalProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const [user, setUser] = React.useState<RPUser>(props.user)
    const activeUser = props.activeUser

    const initialValues: Partial<RPUser> = {
        permissions: props.user.permissions || [],
        email: props.user.email,
    }

    const handleSubmit = (values: Partial<RPUser>) => {
        const updatedUser = {
            ...user,
            ...values,
        }
        setUser(updatedUser)
        props.onSaveChanges(updatedUser)
    }

    const handleClose = (): void => {
        props.onClose()
        setUser(props.user)
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            width="min(40rem, 90vw)"
            title={
                props.mode === 'email'
                    ? 'Change Email Address'
                    : 'Edit Permissions'
            }
            content={
                <div className={localClasses.container}>
                    <div className={localClasses.header}>
                        <Avatar
                            sx={{
                                width: {
                                    xs: '3.5rem',
                                    md: '4.75rem',
                                },
                                height: {
                                    xs: '3.5rem',
                                    md: '4.75rem',
                                },
                            }}
                            src={user.profile_picture_uri ?? undefined}
                        />
                        <div>
                            <Typography variant="h4">
                                {user.first_name} {user.last_name}
                            </Typography>
                            <Typography>{user.title}</Typography>
                            {props.mode === 'permissions' && (
                                <Typography fontWeight={300} fontStyle="italic">
                                    {user.email}
                                </Typography>
                            )}
                            {user.date_created && (
                                <Typography
                                    fontWeight={500}
                                    fontSize="1.125rem"
                                    mt="0.5rem"
                                >
                                    {user.status === 'invited'
                                        ? 'Invited'
                                        : 'Added'}{' '}
                                    {format(
                                        new Date(user.date_created),
                                        'MMMM d, y'
                                    )}
                                </Typography>
                            )}
                        </div>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={null}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {props.mode === 'permissions' ? (
                                    <>
                                        <Typography variant="h4">
                                            Permissions
                                        </Typography>
                                        <PermissionsList
                                            user={user}
                                            selectedPermissions={
                                                values.permissions ?? []
                                            }
                                            disabled={
                                                !activeUser.permissions.includes(
                                                    RPUserPermission.ADMIN
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <TextField
                                        variant="outlined"
                                        label="Email Address"
                                        placeholder="johndoe@example.com"
                                        type="email"
                                        value={values.email}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'email',
                                                e.target.value
                                            )
                                        }
                                    />
                                )}
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap="2rem"
                                    mt="1rem"
                                >
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={handleClose}
                                        key="cancel"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        key="save-changes"
                                    >
                                        {props.mode === 'email'
                                            ? 'Save'
                                            : 'Save Changes'}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </div>
            }
            ctas={[]}
        />
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.75rem',
    },
})
