import classNames from 'classnames'
import React, { FC, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { PageProps } from '../lib/entities'
import { LoadingView } from '../components'
import { useStyles } from '../lib/styles/universal'
import { makeStyles } from 'tss-react/mui'
import { logout } from '../lib/utils'

interface NotInOrganizationProps extends PageProps {}

const NotInOrganization: FC<NotInOrganizationProps> = ({ loading }) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()

    React.useEffect(() => {
        document.title = 'Access Denied - ResiliencePoint'
    }, [])

    const handleLogout = useCallback(() => {
        logout('/')
    }, [])
    return loading ? (
        <LoadingView />
    ) : (
        <div className={classNames(classes.body, localClasses.container)}>
            {/* TODO: Image about lacking access */}
            <Typography variant="h4" component="p">
                Sorry, you don't have access to this organization.
            </Typography>
            <Typography>
                Check that you're logged in with the right HazAdapt ID, or
                contact your organization's ResiliencePoint administrator to be
                added to your organization on ResiliencePoint.
            </Typography>
            <Button onClick={handleLogout} variant="contained" color="error">
                Log Out
            </Button>
        </div>
    )
}
export default NotInOrganization

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        maxWidth: '40rem',
        textAlign: 'center',
        padding: '1rem',
    },
})
