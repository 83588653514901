import { FC } from 'react'
import { TextField } from '../atoms'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { colors } from '@hazadapt-git/public-core-base'
import { ProfilePictureForm } from './ProfilePictureForm'
import { IoOpenOutline } from 'react-icons/io5'
import { getEnvVars } from '../../lib/config'
import { makeStyles } from 'tss-react/mui'
import {
    customBorderRadius,
    customHazTealColor,
} from '../../lib/styles/universal'

interface ProfileFormProps {
    firstName: string
    lastName: string
    title: string
    email: string
    hazadapt_id_email?: string
    disableEmail?: boolean
    onFirstNameChange(value: string): void
    onLastNameChange(value: string): void
    onTitleChange(value: string): void
    onEmailChange(value: string): void
    mode: 'setup' | 'manage'
    profilePicture?: string
    onImportClick: React.MouseEventHandler
    onChangeProfilePicture(image?: string): void
    avatarOptions: string[]
    profilePictureFormRef?: React.RefObject<HTMLDivElement>
}

const { authUrl } = getEnvVars()

export const ProfileDetailsForm: FC<ProfileFormProps> = (
    props: ProfileFormProps
) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <TextField
                    variant="outlined"
                    label="First Name"
                    placeholder="John"
                    onChange={(e) => props.onFirstNameChange(e.target.value)}
                    value={props.firstName}
                    helperText={props.mode === 'setup' ? 'Required' : ''}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    variant="outlined"
                    label="Last Name"
                    placeholder="Doe"
                    onChange={(e) => props.onLastNameChange(e.target.value)}
                    value={props.lastName}
                    helperText={props.mode === 'setup' ? 'Required' : ''}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    variant="outlined"
                    label="Title"
                    placeholder="Emergency Management Coordinator"
                    onChange={(e) => props.onTitleChange(e.target.value)}
                    value={props.title}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                gap="0.5rem"
            >
                <TextField
                    variant="outlined"
                    label="Email Address"
                    placeholder="johndoe@example.com"
                    onChange={(e) => props.onEmailChange(e.target.value)}
                    value={props.email}
                    type="email"
                    disabled={props.disableEmail}
                />
                <Typography color={colors.grays.THUNDERCLOUD} variant="body2">
                    To change the email address on your ResiliencePoint profile,
                    ask your organization's ResiliencePoint administrator.
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                gap="0.5rem"
            >
                <TextField
                    variant="outlined"
                    label="HazAdapt ID"
                    value={props.hazadapt_id_email}
                    type="email"
                    disabled
                />
                <Typography color={colors.grays.THUNDERCLOUD} variant="body2">
                    Go to your{' '}
                    <Link href={authUrl} target="_blank">
                        HazAdapt ID settings <IoOpenOutline />
                    </Link>{' '}
                    to change your HazAdapt ID email.
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                gap="0.5rem"
            >
                <TextField
                    variant="outlined"
                    label="Password"
                    value="****************"
                    type="password"
                    disabled
                />
                <Typography color={colors.grays.THUNDERCLOUD} variant="body2">
                    Go to your{' '}
                    <Link href={authUrl} target="_blank">
                        HazAdapt ID settings <IoOpenOutline />
                    </Link>{' '}
                    to change your password.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div
                    ref={props.profilePictureFormRef}
                    className={localClasses.profilePictureFormWrapper}
                >
                    <ProfilePictureForm
                        profilePicture={props.profilePicture}
                        onImportClick={props.onImportClick}
                        onChangeProfilePicture={props.onChangeProfilePicture}
                        presets={props.avatarOptions}
                    />
                </div>
            </Grid>
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    profilePictureFormWrapper: {
        transition: 'all 0.5s ease-in-out',
        borderRadius: customBorderRadius,
        padding: '1rem',
        '&.highlighted': {
            borderColor: customHazTealColor,
            boxShadow: `0 0 0.5rem ${customHazTealColor}`,
        },
    },
})
