import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { InsightsPage, InsightsPageProps } from '../organisms'

interface PrepCheckInsightsTemplateProps extends InsightsPageProps {}

export const PrepCheckInsightsTemplate: FC<PrepCheckInsightsTemplateProps> = (
    props: PrepCheckInsightsTemplateProps
) => {
    const { classes: localClasses } = useLocalStyles()

    return (
        <div className={localClasses.container}>
            <InsightsPage {...props} />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
})
