import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import mapboxgl from 'mapbox-gl'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import psl from 'psl'

import { Showcase } from './components/demos/Showcase'
import { store } from './lib/store'
import { OrgRouter } from './routes'
import { theme } from './lib/styles/universal'
import { RootRouter } from './routes/Root'

if (process.env.REACT_APP_MAPBOX_API_KEY) {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY
}

function App() {
    const host = psl.parse(window.location.host)
    if (host.error) {
        console.error(host.error)
        return null
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    {process.env.REACT_APP_MODE === 'showcase' ? (
                        <Showcase />
                    ) : (
                        <BrowserRouter>
                            {host.subdomain &&
                            host.subdomain.split('.').length === 2 ? (
                                <OrgRouter />
                            ) : (
                                <RootRouter />
                            )}
                        </BrowserRouter>
                    )}
                </Provider>
            </ThemeProvider>
        </LocalizationProvider>
    )
}

export default App
