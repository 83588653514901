import React, { FC } from 'react'
import { Dialog } from '../atoms'
import Button from '@mui/material/Button'
import { makeStyles } from 'tss-react/mui'
import mapboxgl from 'mapbox-gl'
import { Jurisdiction, colors } from '@hazadapt-git/public-core-base'

interface ViewJurisdictionModalProps {
    jurisdiction: Jurisdiction
    // Dialog props
    open?: boolean
    onClose(): void
}

const TILE_ID = 'jurisdiction'

export const ViewJurisdictionModal: FC<ViewJurisdictionModalProps> = (
    props: ViewJurisdictionModalProps
) => {
    const [mapContainer, setMapContainer] =
        React.useState<HTMLDivElement | null>(null)
    const map = React.useRef<mapboxgl.Map | null>(null)

    const addJurisdictionToMap = React.useCallback(() => {
        if (!map.current) return

        const source = map.current.getSource(TILE_ID)
        if (!source) {
            map.current.addSource(TILE_ID, {
                type: 'geojson',
                data: {
                    ...props.jurisdiction,
                    features: props.jurisdiction.features.map((f) => ({
                        ...f,
                    })),
                },
            })
            map.current.addLayer({
                id: `${TILE_ID}-outline`,
                type: 'line',
                source: TILE_ID,
                paint: {
                    'line-color': colors.primary.CORAL,
                    'line-width': 2,
                },
            })
        }
    }, [props.jurisdiction])

    React.useEffect(() => {
        if (!mapContainer) return
        const point = props.jurisdiction.features.find(
            (f) => f.geometry.type === 'Point'
        )
        const center =
            point && 'coordinates' in point.geometry
                ? point.geometry.coordinates.flat()
                : [0, 0]
        map.current = new mapboxgl.Map({
            container: mapContainer,
            style: 'mapbox://styles/mapbox/light-v11',
            center:
                center.length >= 2
                    ? [
                          parseFloat(center[0].toString()),
                          parseFloat(center[1].toString()),
                      ]
                    : [0, 0],
            zoom: point?.properties?.defaultZoom ?? 10,
        })

        map.current.on('load', () => {
            if (!map.current) return
            map.current.resize()
            addJurisdictionToMap()
        })
    }, [addJurisdictionToMap, mapContainer, props.jurisdiction])

    const { classes: localClasses } = useLocalStyles()
    return (
        <Dialog
            open={props.open}
            title="View Jurisdiction"
            content={
                <div className={localClasses.mapContainer}>
                    <div ref={setMapContainer} className={localClasses.map} />
                </div>
            }
            ctas={[
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={props.onClose}
                    key="close"
                >
                    Close
                </Button>,
            ]}
            width="min(90vw, 37rem)"
            onClose={props.onClose}
        />
    )
}

const useLocalStyles = makeStyles()({
    mapContainer: {
        height: 'min(70vh, 35rem)',
        width: '100%',
        maxWidth: '35rem',
    },
    map: {
        height: '100%',
    },
})
