import { InsightQueryType } from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'

import { HomePageTemplate, LoadingView } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { useAppSelector } from '../lib/store'
import { exportInsights } from '../lib/utils/insights'
import { getEnvVars } from '../lib/config'
import { getHazardData, getPrepCheckData } from '../lib/utils/cms'
import { formatDateRange } from '../lib/utils'
import { useInsights } from '../lib/hooks/useInsights'

interface HomePageProps extends NamespacedPageProps {}

const { publicAppUriBase } = getEnvVars()
const HomePage: FC<HomePageProps> = ({ user, organization, loading }) => {
    const {
        insights,
        activeQuery,
        refreshInsights,
        quickStatRowTitle,
        retrievingInsights,
    } = useInsights({
        organization,
        type: InsightQueryType.CORE,
    })

    // Store data
    const trendingHazards = useAppSelector(
        (state) => state.content.trendingHazards
    )
    const hasLocalContent = useAppSelector(
        (state) => state.content.has_local_content
    )

    React.useEffect(() => {
        document.title = 'Home - ResiliencePoint'
    }, [])

    React.useEffect(() => {
        getHazardData()
        getPrepCheckData()
    }, [organization])

    const openTrendingHazard = (id: number): void => {
        window.open(`${publicAppUriBase}/hazards/${id}`, '_blank')
    }

    return organization && user ? (
        <HomePageTemplate
            pageTitle={`Overall HazAdapt Activity - ${organization.name}`}
            dateRange={formatDateRange(
                activeQuery?.start_date,
                activeQuery?.end_date
            )}
            trendingHazardData={trendingHazards}
            showLocalContentDisclaimer={hasLocalContent}
            onReloadPress={refreshInsights}
            updatedDate={
                activeQuery ? new Date(activeQuery.date_last_used) : undefined
            }
            onDownloadPress={() =>
                exportInsights(
                    InsightQueryType.CORE,
                    organization.slug,
                    activeQuery
                )
            }
            insights={insights}
            quickStatRowTitle={quickStatRowTitle}
            loading={loading}
            retrievingInsights={retrievingInsights}
            onPressTrendingHazard={openTrendingHazard}
        />
    ) : (
        <LoadingView />
    )
}
export default HomePage
