import {
    colors,
    Insight,
    InsightQueryBase,
    InsightQueryDTO,
    InsightQueryType,
    JurisdictionChunkProperties,
    MapAreaLevel,
    MapInsightItem,
    Organization,
    InsightSortMethod,
    PickerItem,
} from '@hazadapt-git/public-core-base'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RefreshInsightArgs } from '../entities'
import {
    buildQueryAreaName,
    buildQueryDateRange,
    exportInsights,
    getInsights,
    insightOptions,
    pairResidentAndVisitorInsights,
    persistLocalPickerChangesOnNewConfig,
    refreshInsight,
    saveQuery,
    segmentResidentAndVisitorInsights,
} from '../utils'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { InsightViewProps, Picker, QueryBuilderValues } from '../../components'
import { useAppSelector } from '../store'
import { Feature, Geometry } from 'geojson'
import { setLastUpdated } from '../slices/content'
import { useDispatch } from 'react-redux'

interface InsightActionsProps {
    organization?: Organization | null
    type: InsightQueryType
}

const handleUpdateSelection = async (
    key: string,
    value: string,
    placement_id: string,
    activeQuery: InsightQueryBase,
    onUpdate?: (placement_id: string, args: InsightQueryBase) => Promise<void>
) => {
    const config = { ...activeQuery.grid_config[placement_id] }
    if (!(key in config.pickers) || !activeQuery) return
    config.pickers[key].selected_option = value
    await onUpdate?.(placement_id, {
        ...activeQuery,
        grid_config: {
            ...activeQuery.grid_config,
            [placement_id]: config,
        },
    })
}

export const useTransformRawHeadlineToNode = () => {
    const transformRawHeadlineToNode = useCallback(
        (
            raw_headline: string | undefined,
            placement_id: string,
            activeQuery: InsightQueryBase,
            onUpdate: (
                placement_id: string,
                args: InsightQueryBase
            ) => Promise<void>
        ): React.ReactNode => {
            if (!raw_headline) return null
            const pieces: string[] = raw_headline.split(
                /(?!{.*)\s(?![^{]*?\})/g
            )

            const results: React.ReactNode[] = []
            pieces.forEach((piece, index) => {
                if (!/{(.*?)}/g.test(piece)) {
                    // Just a string, not a picker placeholder, save and continue
                    results.push(index < pieces.length ? `${piece} ` : piece)
                    return
                }
                // We have a picker placeholder
                const key = piece.replaceAll(/{|}/g, '')
                switch (key) {
                    case 'date_range': {
                        // TODO
                        break
                    }
                    default: {
                        if (
                            !activeQuery.grid_config[placement_id]?.pickers[key]
                        )
                            break
                        const insight_config =
                            activeQuery.grid_config[placement_id]
                        const result: React.ReactNode = (
                            <Picker
                                key={key}
                                data={insight_config.pickers[key].options}
                                id={key}
                                value={
                                    insight_config.pickers[key].selected_option
                                }
                                onChange={(value: string) =>
                                    handleUpdateSelection(
                                        key,
                                        value,
                                        placement_id,
                                        activeQuery,
                                        onUpdate
                                    )
                                }
                                selectColor={colors.chips.LIGHTSEA}
                                variableSize
                                variant="outlined"
                            />
                        )
                        results.push(result)
                        break
                    }
                }
            })
            const finalChunks: React.ReactNode[] = []
            results.forEach((r) => {
                if (
                    finalChunks.length === 0 ||
                    typeof r !== 'string' ||
                    typeof r !== typeof finalChunks[finalChunks.length - 1]
                ) {
                    finalChunks.push(r)
                    return
                }
                finalChunks[finalChunks.length - 1] += r
            })
            return finalChunks
        },
        []
    )

    return transformRawHeadlineToNode
}

export const useBuildInsightGridData = () => {
    const organization = useAppSelector((state) => state.base.organization)
    const transformRawHeadlineToNode = useTransformRawHeadlineToNode()
    const navigate = useNavigate()

    const handleCtaClick = useCallback(
        (href: string) => {
            navigate(href)
        },
        [navigate]
    )

    const buildInsightGridData = useCallback(
        (
            insights: Insight[] | undefined,
            activeQuery: InsightQueryDTO | undefined,
            onRunQueryPress: (
                values: QueryBuilderValues,
                query_config?: InsightQueryDTO
            ) => Promise<void>,
            onUpdate: (
                placement_id: string,
                args: InsightQueryBase & RefreshInsightArgs,
                selectedAreas?: Set<string>
            ) => Promise<void>
        ): InsightViewProps[] | undefined => {
            if (!organization || !insights || !activeQuery) return undefined
            const insightGridData: InsightViewProps[] = []

            for (const insight of insights) {
                switch (insight.type) {
                    case 'single-counter': {
                        break
                    }
                    case 'survey-answer': {
                        break
                    }
                    case 'map': {
                        // MapInsight

                        let centerPiece:
                            | Feature<
                                  Geometry,
                                  MapInsightItem | JurisdictionChunkProperties
                              >
                            | undefined = insight.map_config.features.find(
                            (f) => f.geometry.type === 'Point'
                        )
                        if (
                            centerPiece?.geometry?.type !== 'Point' &&
                            !(activeQuery.zips && activeQuery.zips.length > 0)
                        ) {
                            // No specific point on insight.map_config, and querying over specific area;
                            // do not reset center to org jurisdiction center
                            centerPiece =
                                organization.jurisdiction.features.find(
                                    (f) => f.geometry.type === 'Point'
                                )
                        }

                        const insightView: InsightViewProps = {
                            ...insight,
                            config: activeQuery.grid_config[
                                insight.placement_id
                            ],
                            options: insightOptions(
                                organization.slug,
                                activeQuery.type,
                                insight.placement_id,
                                activeQuery
                            ),
                            ctas: insight.ctas,
                            handleCtaClick,
                            headline: transformRawHeadlineToNode(
                                insight.headline,
                                insight.placement_id,
                                activeQuery,
                                onUpdate
                            ),
                            defaultSelectedAreas: [
                                ...(activeQuery?.zips ?? []),
                                ...(activeQuery?.counties ?? []),
                                ...(activeQuery?.states ?? []),
                            ],
                            defaultZoom: centerPiece?.properties.defaultZoom,
                            defaultCenter:
                                centerPiece?.geometry?.type === 'Point'
                                    ? centerPiece?.geometry.coordinates
                                    : undefined,
                            onSwitchVariant: async (value) => {
                                await handleUpdateSelection(
                                    'variant',
                                    value,
                                    insight.placement_id,
                                    activeQuery,
                                    onUpdate
                                )
                            },
                            onSwitchColorTheme: (value) => {
                                handleUpdateSelection(
                                    'theme',
                                    value,
                                    insight.placement_id,
                                    activeQuery
                                )
                            },
                            onSwitchMode: async (value) => {
                                await handleUpdateSelection(
                                    'mode',
                                    value,
                                    insight.placement_id,
                                    activeQuery,
                                    onUpdate
                                )
                            },
                            onUpdate: async (
                                placeholder_id,
                                bbox,
                                zoom,
                                selectedAreas,
                                reset = false
                            ) => {
                                const query = { ...activeQuery }
                                if (reset) {
                                    if (placeholder_id in query.grid_config) {
                                        query.grid_config[placeholder_id].bbox =
                                            bbox
                                        query.grid_config[placeholder_id].zoom =
                                            zoom
                                    }
                                    return onRunQueryPress(
                                        {
                                            pickerOneValue:
                                                activeQuery.type ===
                                                InsightQueryType.HAZARDS
                                                    ? activeQuery.hazards
                                                    : [
                                                          InsightQueryType.PREP_CHECKS,
                                                          InsightQueryType.PREP_CHECK_QUESTION,
                                                      ].includes(
                                                          activeQuery.type
                                                      )
                                                    ? activeQuery.prep_checks
                                                    : undefined,
                                            pickerTwoValue: selectedAreas
                                                ? Array.from(selectedAreas)
                                                : [],
                                            start_date:
                                                activeQuery.start_date ?? null,
                                            end_date:
                                                activeQuery.end_date ?? null,
                                        },
                                        query
                                    )
                                } else {
                                    return onUpdate(
                                        placeholder_id,
                                        {
                                            ...activeQuery,
                                            bbox,
                                            zoom,
                                        },
                                        selectedAreas
                                    )
                                }
                            },
                        }
                        insightGridData.push(insightView)
                        break
                    }
                    default: {
                        const insightView: InsightViewProps = {
                            ...insight,
                            type: insight.type,
                            options: insightOptions(
                                organization.slug,
                                activeQuery.type,
                                insight.placement_id,
                                activeQuery
                            ),
                            ctas: insight.ctas, // TODO
                            handleCtaClick,
                            headline: transformRawHeadlineToNode(
                                insight.headline,
                                insight.placement_id,
                                activeQuery,
                                onUpdate
                            ),
                        } as InsightViewProps
                        insightGridData.push(insightView)
                        break
                    }
                }
            }

            return insightGridData
        },
        [organization, transformRawHeadlineToNode, handleCtaClick]
    )
    return buildInsightGridData
}

export const useInsights = ({ organization, type }: InsightActionsProps) => {
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()

    const [insightData, setInsightData] = useState<Insight[]>()
    const [quickStatRowTitle, setQuickStatRowTitle] = useState<string>()
    const [activeQuery, setActiveQuery] = useState<InsightQueryDTO>()
    const [retrievingInsights, setRetrievingInsights] = useState<boolean>(true)

    const buildInsightGridData = useBuildInsightGridData()

    useEffect(() => {
        getInsights({
            type,
            searchParams,
            recenter_map: true,
        })
            .then((res) => {
                const { insights, quick_stat_row_title, ...query } = res
                setInsightData(insights)
                setQuickStatRowTitle(quick_stat_row_title)
                setActiveQuery(query)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [searchParams, type])

    const refreshInsights = useCallback(
        async (
            existingQuery = activeQuery,
            reset = false,
            updatedParams = false
        ) => {
            if (!existingQuery) return
            setRetrievingInsights(true)
            getInsights({
                ...existingQuery,
                type,
                start_date:
                    existingQuery.id > 0 || updatedParams
                        ? existingQuery.start_date
                        : undefined,
                end_date:
                    existingQuery.id > 0 || updatedParams
                        ? existingQuery.end_date
                        : undefined,
                reset,
            })
                .then((res) => {
                    const { insights, quick_stat_row_title, ...newQuery } = res
                    persistLocalPickerChangesOnNewConfig(
                        newQuery.grid_config,
                        existingQuery.grid_config
                    )
                    setActiveQuery(newQuery)
                    setInsightData(insights)
                    setQuickStatRowTitle(quick_stat_row_title)
                })
                .catch(console.error)
                .finally(() => setRetrievingInsights(false))
        },
        [activeQuery, type]
    )

    const refreshOneInsight = useCallback(
        async (
            placement_id: string,
            args: InsightQueryBase & RefreshInsightArgs,
            selectedAreas?: Set<string>
        ) => {
            if (!organization) return
            args.grid_config = {
                ...args.grid_config,
                [placement_id]: {
                    ...args.grid_config[placement_id],
                    bbox: args.bbox,
                    zoom: args.zoom,
                },
            }
            delete args.bbox
            delete args.zoom
            let selectedAreaLevel: MapAreaLevel | undefined = undefined
            if (selectedAreas) {
                if (organization.areas.zip.some((a) => selectedAreas.has(a)))
                    selectedAreaLevel = MapAreaLevel.ZIP
                else if (
                    organization.areas.county.some((a) => selectedAreas.has(a))
                )
                    selectedAreaLevel = MapAreaLevel.COUNTY
                else if (
                    organization.areas.state.some((a) => selectedAreas.has(a))
                )
                    selectedAreaLevel = MapAreaLevel.STATE
            }

            const zips =
                selectedAreas && selectedAreaLevel === MapAreaLevel.ZIP
                    ? Array.from(selectedAreas)
                    : undefined
            const counties =
                selectedAreas && selectedAreaLevel === MapAreaLevel.COUNTY
                    ? Array.from(selectedAreas)
                    : undefined
            const states =
                selectedAreas && selectedAreaLevel === MapAreaLevel.STATE
                    ? Array.from(selectedAreas)
                    : undefined
            setActiveQuery((aq) =>
                aq
                    ? {
                          ...aq,
                          ...args,
                          zips,
                          counties,
                          states,
                      }
                    : undefined
            )
            args.zips = zips
            args.counties = counties
            args.states = states
            const newInsight = await refreshInsight(placement_id, type, args)
            const currInsights = [...(insightData ?? [])]
            const index = currInsights.findIndex(
                (i) => i.placement_id === placement_id
            )
            if (index < 0) currInsights.push(newInsight)
            else currInsights.splice(index, 1, newInsight)
            setInsightData(currInsights)
        },
        [insightData, organization, type]
    )

    const onSaveQuery = useCallback(async () => {
        if (!activeQuery) return
        saveQuery({
            ...activeQuery,
            type,
        })
    }, [activeQuery, type])

    const onRunQueryPress = useCallback(
        (values: QueryBuilderValues, query = activeQuery) =>
            new Promise<void>((resolve, reject) => {
                if (!query) {
                    reject('No query to run')
                    return
                }
                const pickerTwoValues: string[] = Array.isArray(
                    values.pickerTwoValue
                )
                    ? values.pickerTwoValue
                    : [values.pickerTwoValue]
                const fullJurisdiction =
                    pickerTwoValues.length === 1 &&
                    pickerTwoValues[0] === 'jurisdiction'
                const active_query: InsightQueryDTO = {
                    ...query,
                    hazards:
                        type === InsightQueryType.HAZARDS
                            ? values.pickerOneValue?.toString()
                                ? [parseInt(values.pickerOneValue.toString())]
                                : []
                            : undefined,
                    prep_checks: [
                        InsightQueryType.PREP_CHECKS,
                        InsightQueryType.PREP_CHECK_QUESTION,
                    ].includes(type)
                        ? values.pickerOneValue?.toString()
                            ? [parseInt(values.pickerOneValue.toString())]
                            : []
                        : undefined,
                    zips: fullJurisdiction
                        ? []
                        : pickerTwoValues.filter((v) =>
                              organization?.areas.zip.includes(v)
                          ),
                    counties: fullJurisdiction
                        ? []
                        : pickerTwoValues.filter((v) =>
                              organization?.areas.county.includes(v)
                          ),
                    states: fullJurisdiction
                        ? []
                        : pickerTwoValues.filter((v) =>
                              organization?.areas.state.includes(v)
                          ),
                    start_date: values.start_date ?? undefined,
                    end_date: values.end_date ?? undefined,
                }

                refreshInsights(active_query, undefined, true)
                    .then(resolve)
                    .catch(reject)
                dispatch(setLastUpdated())
            }),
        [activeQuery, refreshInsights, type, dispatch, organization]
    )

    const onReloadPress = useCallback(() => {
        if (!activeQuery) return
        refreshInsights(activeQuery, undefined, true)
    }, [activeQuery, refreshInsights])

    const onResetQueryPress = useCallback(() => {
        setRetrievingInsights(true)
        getInsights({
            type,
            reset: true,
            recenter_map: true,
        })
            .then((res) => {
                const { insights, quick_stat_row_title, ...query } = res
                setInsightData(insights)
                setQuickStatRowTitle(quick_stat_row_title)
                setActiveQuery(query)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [type])

    const onDownloadReportPress = useCallback(() => {
        if (!organization) return
        exportInsights(type, organization.slug, activeQuery)
    }, [activeQuery, type, organization])

    const selectedDatesString = useMemo(
        () => buildQueryDateRange(activeQuery),
        [activeQuery]
    )

    const selectedAreas = useMemo(
        () => [
            ...(activeQuery?.zips ?? []),
            ...(activeQuery?.counties ?? []),
            ...(activeQuery?.states ?? []),
        ],
        [activeQuery]
    )

    const selectedAreaName = useMemo(() => {
        return buildQueryAreaName(activeQuery)
    }, [activeQuery])

    const insights = useMemo(() => {
        const data = buildInsightGridData(
            insightData,
            activeQuery,
            onRunQueryPress,
            refreshOneInsight
        )
        switch (activeQuery?.sort_method) {
            case InsightSortMethod.PAIR_RESIDENTS_VISITORS: {
                pairResidentAndVisitorInsights(data)
                break
            }
            case InsightSortMethod.SEGMENT_RESIDENTS_VISITORS: {
                segmentResidentAndVisitorInsights(data)
                break
            }
            default: {
                break
            }
        }
        return data
    }, [
        buildInsightGridData,
        insightData,
        activeQuery,
        onRunQueryPress,
        refreshOneInsight,
    ])

    const availableSortMethods: PickerItem<InsightSortMethod>[] =
        useMemo(() => {
            if (!activeQuery) return []

            const sortMethods: PickerItem<InsightSortMethod>[] = []
            switch (activeQuery.type) {
                case InsightQueryType.CORE: {
                    break
                }
                case InsightQueryType.HAZARDS: {
                    break
                }
                case InsightQueryType.PREP_CHECKS: {
                    sortMethods.push(
                        {
                            label: 'Separate Resident and Visitor Insights', // TODO: Confirm
                            value: InsightSortMethod.SEGMENT_RESIDENTS_VISITORS,
                        },
                        {
                            label: 'Pair Resident and Visitor Insights', // TODO: Confirm
                            value: InsightSortMethod.PAIR_RESIDENTS_VISITORS,
                        }
                    )
                    break
                }
                default: {
                    break
                }
            }
            return sortMethods
        }, [activeQuery])

    const setInsightSortMethod = useCallback(
        (sort_method: InsightSortMethod) => {
            if (!activeQuery) return
            const query = { ...activeQuery }
            setActiveQuery({
                ...query,
                sort_method,
            })
        },
        [activeQuery]
    )

    return {
        insights,
        quickStatRowTitle,
        activeQuery,
        retrievingInsights,
        refreshInsights,
        refreshOneInsight,
        onSaveQuery,
        onRunQueryPress,
        onReloadPress,
        onResetQueryPress,
        onDownloadReportPress,
        selectedDatesString,
        selectedAreas,
        selectedAreaName,
        availableSortMethods,
        setInsightSortMethod,
    }
}
