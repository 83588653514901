import React, { FC, useState } from 'react'
import {
    DonutChartInsight,
    LineFillInsight,
    LineGraphInsight,
    MultiBarGraphInsight,
    MultiCounterInsight,
    QuickStatInsight,
    SingleBarGraphInsight,
    colors,
} from '@hazadapt-git/public-core-base'
import { BarGraphInsightView } from './BarGraphInsightView'
import { LineGraphInsightView } from './LineGraphInsightView'
import { MapInsightView, MapInsightViewProps } from './MapInsightView'
import {
    defaultMultiBarLimit,
    defaultSingleBarLimit,
    primaryIconSize,
    useStyles,
} from '../../lib/styles/universal'
import { MenuItemProps } from '../../lib/entities'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import { IoEllipsisVertical, IoInformationCircle } from 'react-icons/io5'
import classNames from 'classnames'
import { DonutChartInsightView } from './DonutChartInsightView'
import { LineFillInsightView } from './LineFillInsightView'
import { MultiCounterInsightView } from './MultiCounterInsightView'
import { CustomMenuItem } from '../../lib/entities/misc'
import { QuickStatInsightView } from './QuickStatInsightView'
import { Popover, Switch } from '../atoms'

export interface InsightActionsProps {
    options: MenuItemProps[]
    onDownloadClick?: React.MouseEventHandler
}

export const InsightActions: FC<InsightActionsProps> = (
    props: InsightActionsProps
) => {
    const { classes } = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const onOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const onOptionsClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={classes.insightActions}>
            {props.options.length > 0 && (
                <>
                    <IconButton onClick={onOptionsClick}>
                        <IoEllipsisVertical
                            size={primaryIconSize}
                            color={colors.grays.NOIR}
                        />
                    </IconButton>
                    <Menu
                        id="insight-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={onOptionsClose}
                    >
                        {props.options.map((o) => (
                            <CustomMenuItem
                                {...o}
                                onClick={(e) => {
                                    o.onClick?.(e)
                                    onOptionsClose()
                                }}
                                key={o.label}
                            />
                        ))}
                    </Menu>
                </>
            )}
        </div>
    )
}

type InsightViewBaseProps =
    | Omit<SingleBarGraphInsight, 'headline'>
    | Omit<MultiBarGraphInsight, 'headline'>
    | Omit<LineGraphInsight, 'headline'>
    | Omit<MapInsightViewProps, 'headline'>
    | Omit<DonutChartInsight, 'headline'>
    | Omit<LineFillInsight, 'headline'>
    | Omit<MultiCounterInsight, 'headline'>
    | Omit<QuickStatInsight, 'headline'>

export type InsightViewProps = InsightActionsProps &
    InsightViewBaseProps & {
        placement_id: string
        headline: React.ReactNode
        handleCtaClick?(href: string): void
        expanded?: boolean
    }

export const InsightView: FC<InsightViewProps> = (props: InsightViewProps) => {
    const { classes } = useStyles()
    const [showAllData, setShowAllData] = useState<boolean>(false)

    const isSingleBarGraphInsight = (
        props: InsightViewBaseProps
    ): props is Omit<SingleBarGraphInsight, 'headline'> => {
        return 'bars' in props
    }

    const isMultiBarGraphInsight = (
        props: InsightViewBaseProps
    ): props is Omit<MultiBarGraphInsight, 'headline'> => {
        return 'groups' in props
    }

    const hasData = () => {
        if (props.type === 'bar') {
            if (isSingleBarGraphInsight(props)) {
                return props.bars.some((b) => b.value > 0)
            } else if (isMultiBarGraphInsight(props)) {
                return props.groups.some((g) => g.values.length > 0)
            }
        } else if (props.type === 'donut') {
            return props.chunks.some((ch) => ch.value > 0)
        } else if (props.type === 'line') {
            return props.lines.some((l) => l.values.length > 0)
        } else if (props.type === 'line-fill') {
            return props.lines.length > 0
        } else if (props.type === 'map') {
            return true
        } else if (props.type === 'multi-counter') {
            return props.counters.length > 0
        } else if (props.type === 'quick-stat') {
            return true
        }
    }

    const handleShowAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowAllData(event.target.checked)
    }

    const showSwitch =
        props.type === 'bar' &&
        !props.expanded &&
        (isSingleBarGraphInsight(props)
            ? props.bars.length > defaultSingleBarLimit
            : isMultiBarGraphInsight(props)
            ? props.labels.length > defaultMultiBarLimit
            : false)

    return (
        <div className={classes.insightView}>
            <div className={classes.insightWrapper} id={props.placement_id}>
                {props.type === 'map' ? (
                    <MapInsightView {...props} />
                ) : (
                    <>
                        <div className={classes.insightTitleAndElement}>
                            {props.type !== 'quick-stat' ? (
                                <Box
                                    mb="1rem"
                                    display="flex"
                                    flexDirection="column"
                                    gap="0.5rem"
                                >
                                    <div className={classes.insightHeader}>
                                        <div
                                            className={classes.insightTitle}
                                            style={{
                                                flex: 1,
                                                justifyContent:
                                                    props.headline ===
                                                    'Insights'
                                                        ? 'center'
                                                        : undefined,
                                            }}
                                        >
                                            <Typography
                                                variant="h4"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                {props.headline}
                                            </Typography>
                                            {props.explainer ? (
                                                <Popover
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    icon={
                                                        <IoInformationCircle
                                                            color={
                                                                colors.grays
                                                                    .NIMBUS
                                                            }
                                                        />
                                                    }
                                                    helpText={props.explainer}
                                                />
                                            ) : null}
                                        </div>
                                        <InsightActions
                                            options={props.options}
                                            onDownloadClick={
                                                props.onDownloadClick
                                            }
                                        />
                                    </div>
                                    {props.subline && (
                                        <Typography>{props.subline}</Typography>
                                    )}
                                    {/* Bar Graph `Show All` Switch */}
                                    {showSwitch && (
                                        <Box
                                            margin={'-.25rem 0 -1.75rem .5rem'}
                                        >
                                            <Switch
                                                checked={showAllData}
                                                onChange={handleShowAll}
                                                label="Show All"
                                                size="small"
                                            />
                                        </Box>
                                    )}
                                </Box>
                            ) : null}
                            <Box
                                className={classes.insight}
                                height={
                                    props.type === 'line-fill'
                                        ? '100%'
                                        : undefined
                                }
                            >
                                {hasData() || props.show_if_no_data ? (
                                    props.type === 'bar' ? (
                                        <BarGraphInsightView
                                            {...props}
                                            showAllData={showAllData}
                                        />
                                    ) : props.type === 'line' ? (
                                        <LineGraphInsightView {...props} />
                                    ) : props.type === 'donut' ? (
                                        <DonutChartInsightView {...props} />
                                    ) : props.type === 'line-fill' ? (
                                        <LineFillInsightView {...props} />
                                    ) : props.type === 'multi-counter' ? (
                                        <MultiCounterInsightView {...props} />
                                    ) : props.type === 'quick-stat' ? (
                                        <QuickStatInsightView {...props} />
                                    ) : null
                                ) : (
                                    <Typography margin="auto">
                                        Not enough data
                                    </Typography>
                                )}
                            </Box>
                            {props.ctas && (
                                <div
                                    className={classNames(
                                        classes.buttonGroup,
                                        classes.insightCtas
                                    )}
                                >
                                    {props.ctas.map((cta, index) => (
                                        <Button
                                            key={`${index + 1}-${cta.label
                                                .toLowerCase()
                                                .replaceAll(' ', '-')}`}
                                            onClick={() =>
                                                props.handleCtaClick?.(cta.href)
                                            }
                                            variant="contained"
                                            className={classes.smallButton}
                                        >
                                            {cta.label}
                                        </Button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
