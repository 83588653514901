import React, { FC } from 'react'
import { DonutChartInsight } from '@hazadapt-git/public-core-base'
import { Doughnut } from 'react-chartjs-2'
import { ChartData } from 'chart.js/dist/types/index'

export interface DonutChartInsightViewProps
    extends Omit<DonutChartInsight, 'headline'> {
    headline: React.ReactNode
}

export const DonutChartInsightView: FC<DonutChartInsightViewProps> = (
    props: DonutChartInsightViewProps
) => {
    const getDonutChartChunks = (): ChartData<'doughnut', number[], string> => {
        const labels = props.chunks.map((c) => c.label)
        const dataset = {
            data: [] as number[],
            backgroundColor: [] as string[],
            borderColor: [] as string[],
        }
        for (const chunk of props.chunks) {
            dataset.data.push(chunk.value)
            dataset.backgroundColor.push(chunk.color)
            dataset.borderColor.push(chunk.color)
        }
        return { labels, datasets: [dataset] }
    }

    return (
        <Doughnut
            data={getDonutChartChunks()}
            options={{
                indexAxis: 'y',
                plugins: {
                    legend: {
                        display: true,
                    },
                },
                resizeDelay: 50,
                aspectRatio: 1,
                // maintainAspectRatio: false,
            }}
        />
    )
}
