import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

interface ProgressBarProps {
    progress: number // The percentage of the bar to fill in. On a scale of 0 to 1
    maxWidth?: string | number
}

export const ProgressBar: FC<ProgressBarProps> = (props: ProgressBarProps) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <Box
            className={localClasses.container}
            sx={{ maxWidth: props.maxWidth }}
        >
            <LinearProgress
                variant="determinate"
                value={props.progress * 100}
            />
        </Box>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        flexGrow: 1,
        width: '100%',
    },
})
