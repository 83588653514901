import React from 'react'
import { Dialog } from '../atoms'
import Button from '@mui/material/Button'
import { ImageCropper, ImageEditorDisclaimer } from '../molecules'
import { ReactCropperElement } from 'react-cropper'

interface ConfirmOrganizationLogoModalProps {
    open?: boolean
    title: string
    selectedImage: string
    onImageChange(image: string): void
    onCancelClick(): void
    onSaveClick: React.MouseEventHandler
    onClose(): void
}

export const ConfirmOrganizationLogoModal = React.forwardRef(
    (
        props: ConfirmOrganizationLogoModalProps,
        ref: React.ForwardedRef<ReactCropperElement>
    ) => {
        return (
            <Dialog
                open={!!props.open}
                title={props.title}
                content={
                    <>
                        <ImageCropper
                            selectedImage={props.selectedImage}
                            onImageChange={props.onImageChange}
                            ref={ref}
                        />
                        <ImageEditorDisclaimer />
                    </>
                }
                ctas={[
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={props.onCancelClick}
                        key="cancel"
                    >
                        Cancel
                    </Button>,
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onSaveClick}
                        key="save"
                    >
                        Save
                    </Button>,
                ]}
                onClose={props.onClose}
            />
        )
    }
)
