import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { CustomMenuItem } from '../../lib/entities/misc'
import { logout, useWindowSizeUp } from '../../lib/utils'
import { colors } from '@hazadapt-git/public-core-base'

interface ProfileLinkWithAvatarProps {
    src?: string
    name: string
}

export const ProfileLinkWithAvatar: FC<ProfileLinkWithAvatarProps> = (
    props: ProfileLinkWithAvatarProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const lgScreens = useWindowSizeUp('md')

    const onAvatarPress = (e: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) setAnchorEl(null)
        else setAnchorEl(e.currentTarget)
    }
    const onClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={localClasses.container} onClick={onAvatarPress}>
            <Avatar
                sx={{
                    width: lgScreens ? '2.5rem' : '2rem',
                    height: lgScreens ? '2.5rem' : '2rem',
                    cursor: 'pointer',
                }}
                src={props.src}
            />
            <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
                <MenuItem
                    disabled
                    sx={{
                        fontWeight: 500,
                        '&.Mui-disabled': { opacity: 1 },
                    }}
                >
                    Hi, {props.name}!
                </MenuItem>
                <CustomMenuItem
                    label="Log Out"
                    onClick={() => logout()}
                    sx={{
                        color: colors.primary.CORAL,
                        fontWeight: 500,
                    }}
                />
            </Menu>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        cursor: 'pointer',
    },
})
