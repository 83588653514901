import {
    ExportFormat,
    InsightQueryHistory,
} from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'

import { SavedQueriesPageTemplate } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { getSavedQueries } from '../lib/utils'
import { exportInsights } from '../lib/utils/insights'
import { useSearchParams } from 'react-router-dom'

interface SavedQueriesPageProps extends NamespacedPageProps {}

const SavedQueriesPage: FC<SavedQueriesPageProps> = ({
    organization,
    user,
    loading,
}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [queries, setQueries] = React.useState<InsightQueryHistory>()

    React.useEffect(() => {
        const page = parseInt(searchParams.get('page') ?? '1')
        getSavedQueries(page).then(setQueries).catch(console.error)
    }, [searchParams])

    React.useEffect(() => {
        document.title = 'Saved Queries - ResiliencePoint'
    }, [])

    const onGenerateReport = React.useCallback(
        async (id: number, format?: ExportFormat): Promise<void> => {
            if (!organization || !queries) return

            const queryList = [...queries.yours, ...(queries.others ?? [])]
            const query = queryList.find((q) => q.id === id)
            if (!query) return

            await exportInsights(
                query.type,
                organization.slug,
                query,
                format,
                []
            )
        },
        [queries, organization]
    )

    const sortedQueries = React.useMemo(() => {
        if (!queries) return
        if (queries.yours?.length === 0 && queries.others?.length === 0)
            return []
        const queryList = [...queries.yours, ...(queries.others ?? [])]
        queryList.sort(
            (a, b) =>
                new Date(b.date_last_used).getTime() -
                new Date(a.date_last_used).getTime()
        )
        return queryList
    }, [queries])

    const onPaginate = (e: React.ChangeEvent, page: number) => {
        if (page < 1) return
        setQueries(undefined)
        const params = new URLSearchParams(searchParams)
        params.set('page', page.toString())
        setSearchParams(params)
    }

    return (
        <SavedQueriesPageTemplate
            organization={organization}
            user={user}
            queries={sortedQueries}
            onGenerateReport={onGenerateReport}
            page={
                queries?.page ?? parseInt(searchParams.get('page') ?? '1') ?? 1
            }
            numPages={queries?.num_pages ?? 0}
            onPaginate={onPaginate}
            loading={loading}
        />
    )
}
export default SavedQueriesPage
