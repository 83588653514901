import { Elements } from '@stripe/react-stripe-js'
import {
    StripeElementsOptionsClientSecret,
    loadStripe,
} from '@stripe/stripe-js'
import { FC } from 'react'
import { rubikFontUrl } from '../../lib/entities'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '')

interface StripeContainerProps {
    options?: StripeElementsOptionsClientSecret
    children: React.ReactNode
}

export const StripeContainer: FC<StripeContainerProps> = (
    props: StripeContainerProps
) => (
    <Elements
        stripe={stripePromise}
        options={{
            ...props.options,
            fonts: [{ cssSrc: rubikFontUrl }],
        }}
        key={props.options?.clientSecret}
    >
        {props.children}
    </Elements>
)
