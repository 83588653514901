import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { customBorderRadius, theme } from '../../lib/styles/universal'
import { colors, OrganizationWithMembers } from '@hazadapt-git/public-core-base'
import { IoOpenOutline } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'
import {
    SimplifiedSeatSubscription,
    useSubscription,
} from '../../lib/hooks/useSubscription'
import { makeStyles } from 'tss-react/mui'
import { SeatSubscriptionTable } from '../organisms/SeatSubscriptionTable'

interface ManageSubscriptionsTemplateProps {
    page: 'manage' | 'cancel' | 'success'
    organization: OrganizationWithMembers
    onNavigateToCancelSeats(sub: SimplifiedSeatSubscription): void
    checked: boolean
    setChecked: Dispatch<SetStateAction<boolean>>
    subscriptionType: 'core' | 'seat'
}

export const ManageSubscriptionsTemplate: FC<
    ManageSubscriptionsTemplateProps
> = ({
    page,
    organization,
    onNavigateToCancelSeats,
    checked,
    setChecked,
    subscriptionType,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const mdScreen = useWindowSizeUp('md')
    const { rootSubscriptionEndDate } = useSubscription()

    const seatsRemaining = organization
        ? organization.seats - organization.members.length
        : null

    return (
        <>
            {page === 'manage' && (
                <>
                    {/* Current seat tally */}
                    {organization && organization.seats > 0 ? (
                        <Typography
                            className={localClasses.mdBoldText}
                            component={'span'}
                        >
                            You are currently using{' '}
                            <Typography
                                fontSize={'inherit'}
                                fontWeight={700}
                                component={'span'}
                            >
                                {seatsRemaining} out of {organization.seats}
                            </Typography>{' '}
                            seats
                        </Typography>
                    ) : (
                        <Typography
                            className={localClasses.mdBoldText}
                            component={'span'}
                        >
                            You currently have no seats
                        </Typography>
                    )}
                    <SeatSubscriptionTable
                        onNavigateToCancelSeats={onNavigateToCancelSeats}
                    />
                </>
            )}
            {page === 'cancel' && (
                <Stack gap={'2rem'} maxWidth={'55rem'}>
                    <Typography className={localClasses.mdBoldText}>
                        We're sorry to hear that you'd like to cancel your
                        ResiliencePoint{' '}
                        {subscriptionType === 'seat' && 'Seats '}Subscription
                    </Typography>
                    <Typography
                        variant={mdScreen ? 'body1' : 'body2'}
                        fontWeight={500}
                    >
                        After the final day of your subscription,{' '}
                        {subscriptionType === 'seat'
                            ? "you will no longer have access to the cancelled seats. Please ensure that you've removed any users from any seats you wish to cancel."
                            : 'all associated local safety information, including hazard guides, will be removed from HazAdapt.'}
                    </Typography>
                    {subscriptionType === 'core' && (
                        <Typography
                            variant={mdScreen ? 'body1' : 'body2'}
                            fontWeight={500}
                        >
                            Please be aware that once your subscription ends,
                            you will no longer have access to delete or make
                            changes to this information.
                        </Typography>
                    )}
                    {/* Contact us */}
                    <Stack
                        gap={'1rem'}
                        className={localClasses.contactUsContainer}
                    >
                        <Typography className={localClasses.mdBoldText}>
                            Have any question or issues?
                        </Typography>
                        <Typography variant={mdScreen ? 'body1' : 'body2'}>
                            Feel free to reach out to our support with questions
                            or concerns.
                        </Typography>
                        <Button
                            sx={{ alignSelf: 'end' }}
                            href="https://www.hazadapt.com/contact"
                            target="_blank"
                        >
                            Contact Us{' '}
                            <IoOpenOutline style={{ marginLeft: '.25rem' }} />
                        </Button>
                    </Stack>
                    {/* Checkbox */}
                    <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                            />
                        }
                        label={
                            <>
                                <Typography
                                    variant={mdScreen ? 'body1' : 'body2'}
                                    fontWeight={500}
                                >
                                    I have read and understand that after the
                                    last day of my subscription{' '}
                                    {subscriptionType === 'seat'
                                        ? 'I will no longer have access to the cancelled seats.'
                                        : 'all associated local safety information, including hazard guides, will be removed from HazAdapt.'}
                                </Typography>
                                <Typography
                                    variant={mdScreen ? 'body1' : 'body2'}
                                >
                                    {subscriptionType === 'seat'
                                        ? 'I have removed users from the seats that I plan to cancel.'
                                        : 'I acknowledge that I will no longer have access to this information once my subscription ends.'}
                                </Typography>
                            </>
                        }
                    />
                </Stack>
            )}
            {page === 'success' && (
                <>
                    <div className={localClasses.cancelSuccessContainer}>
                        <Typography
                            variant={mdScreen ? 'body1' : 'body2'}
                            fontWeight={500}
                        >
                            {subscriptionType === 'seat'
                                ? 'Seats Subscription cancelled successfully.'
                                : 'Cancellation - Pending'}
                        </Typography>
                        <Typography
                            variant={mdScreen ? 'body1' : 'body2'}
                            fontWeight={500}
                        >
                            A confirmation email has been sent to all
                            organization administrators.
                        </Typography>
                    </div>
                    <Typography
                        variant={mdScreen ? 'body1' : 'body2'}
                        fontWeight={500}
                    >
                        We're sorry to see you go!{' '}
                        {subscriptionType === 'core' &&
                            `Your subscription will remain active until ${rootSubscriptionEndDate}.`}
                    </Typography>
                    {subscriptionType === 'core' && (
                        <>
                            <Typography variant="body2">
                                <strong>Please note:</strong> After the final
                                day of your subscription, all associated local
                                safety information, including hazard guides,
                                will be removed from HazAdapt.
                            </Typography>
                            <Typography variant="body2">
                                Please be aware that once your subscription
                                ends, you will no longer have access to delete
                                or make changes to this information.
                            </Typography>
                        </>
                    )}
                </>
            )}
        </>
    )
}

const useLocalStyles = makeStyles()({
    mdBoldText: {
        fontSize: '1rem',
        fontWeight: 500,
        [theme.breakpoints.up('md')]: { fontSize: '1.25rem' },
    },
    contactUsContainer: {
        backgroundColor: colors.grays.BLANC,
        borderRadius: customBorderRadius,
        boxShadow: theme.shadows[4],
        padding: '1rem .5rem 1rem 1.5rem',
        maxWidth: '20rem',
    },
    cancelSuccessContainer: {
        backgroundColor: colors.secondary.HEAT_WAVE,
        borderRadius: customBorderRadius,
        padding: '1rem 1.5rem',
        maxWidth: '40rem',
    },
})
