import { Breakpoint } from '@mui/system'
import { RefObject, useEffect, useState } from 'react'
import { theme } from '../styles/universal'

/**
 * Checks if an element matches a media query based on its dimensions (width and/or height).
 *
 * @param {RefObject<HTMLElement>} ref - A ref object pointing to the target element.
 * @param {Function} query - A function that takes the element's dimensions and returns a boolean indicating if it matches the media query.
 * @returns {boolean} - A boolean indicating if the element matches the media query.
 *
 * @example
 * const MyComponent = () => {
 *   const ref = useRef(null);
 *   const mdScreens = useElementMediaQuery(ref, (width) => width > theme.breakpoints.values.md);
 *
 *   return (
 *     <div ref={ref}>
 *       <p>{mdScreens ? 'Matches the query' : 'Does not match the query'}</p>
 *     </div>
 *   );
 * };
 */
export const useElementMediaQuery = (
    ref: RefObject<HTMLElement>,
    query: (width: number, height: number) => boolean
) => {
    const [matches, setMatches] = useState(false)

    useEffect(() => {
        if (!ref.current) return

        const element = ref.current
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentRect) {
                    const { width, height } = entry.contentRect
                    const doesMatch = query(width, height)
                    if (doesMatch !== matches) {
                        setMatches(doesMatch)
                    }
                }
            }
        })

        observer.observe(element)

        return () => {
            observer.unobserve(element)
        }
    }, [ref, query, matches])

    return matches
}

/**
 * Matches with elements larger than or equal to the given breakpoint
 * @param ref RefObject<HTMLElement>
 * @param size Breakpoint
 * @returns boolean
 */
export const useElementWidthUp = (
    ref: RefObject<HTMLElement>,
    size: Breakpoint
): boolean => {
    return useElementMediaQuery(
        ref,
        (width) => width >= theme.breakpoints.values[size]
    )
}

/**
 * Matches with elements smaller than or equal to the given breakpoint
 * @param ref RefObject<HTMLElement>
 * @param size Breakpoint
 * @returns boolean
 */
export const useElementWidthDown = (
    ref: RefObject<HTMLElement>,
    size: Breakpoint
): boolean => {
    return useElementMediaQuery(
        ref,
        (width) => width <= theme.breakpoints.values[size]
    )
}

/**
 * Matches with elements with a width between the given start and end breakpoints
 * @param ref RefObject<HTMLElement>
 * @param start Breakpoint
 * @param end Breakpoint
 * @returns boolean
 */
export const useElementWidthBetween = (
    ref: RefObject<HTMLElement>,
    start: Breakpoint,
    end: Breakpoint
): boolean => {
    return useElementMediaQuery(
        ref,
        (width) =>
            width >= theme.breakpoints.values[start] &&
            width < theme.breakpoints.values[end]
    )
}
