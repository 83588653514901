import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ManageProfileView } from '../organisms'

interface SetupProfilePageTemplateProps {
    header: string
    entityName: string
    firstName: string
    lastName: string
    title: string
    email: string
    hazadapt_id_email: string
    profilePicture?: string
    disableEmail?: boolean
    onFirstNameChange(value: string): void
    onLastNameChange(value: string): void
    onTitleChange(value: string): void
    onEmailChange(value: string): void
    onImportClick: React.MouseEventHandler
    onChangeProfilePicture(image?: string): void
    avatarOptions: string[]
    onContinue: React.MouseEventHandler
}

export const SetupProfilePageTemplate: FC<SetupProfilePageTemplateProps> = (
    props: SetupProfilePageTemplateProps
) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div className={localClasses.container}>
            <Typography variant="h4" className={localClasses.welcomeText}>
                Welcome to {props.entityName}'s ResiliencePoint space!
            </Typography>
            {props.header && <Typography>{props.header}</Typography>}
            <Button
                variant="contained"
                color="primary"
                onClick={props.onContinue}
                key="continue"
                sx={{ alignSelf: 'flex-end' }}
            >
                Continue
            </Button>
            <div>
                <ManageProfileView
                    firstName={props.firstName}
                    lastName={props.lastName}
                    title={props.title}
                    email={props.email}
                    hazadapt_id_email={props.hazadapt_id_email}
                    disableEmail={props.disableEmail}
                    profilePicture={props.profilePicture}
                    onFirstNameChange={props.onFirstNameChange}
                    onLastNameChange={props.onLastNameChange}
                    onTitleChange={props.onTitleChange}
                    onEmailChange={props.onEmailChange}
                    onImportClick={props.onImportClick}
                    onChangeProfilePicture={props.onChangeProfilePicture}
                    avatarOptions={props.avatarOptions}
                    mode="setup"
                />
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
    },
    welcomeText: {
        display: 'flex',
        padding: '2rem 0 1rem',
    },
})
