import React, { FC, useCallback, useMemo, useRef } from 'react'
import { ManageProfileView, ProfileOverview } from '../organisms'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useWindowSizeUp } from '../../lib/utils'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import { colors } from '@hazadapt-git/public-core-base'
import { useElementWidthUp } from '../../lib/hooks/useElementMediaQuery'

interface ManageProfilePageTemplateProps {
    firstName: string
    lastName: string
    title: string
    email: string
    hazadapt_id_email: string
    profilePicture: string
    isAdmin?: boolean
    disableEmail?: boolean
    onFirstNameChange(value: string): void
    onLastNameChange(value: string): void
    onTitleChange(value: string): void
    onEmailChange(value: string): void
    onImportClick: React.MouseEventHandler
    onChangeProfilePicture(image?: string): void
    avatarOptions: string[]
    onSaveChangesClick: React.MouseEventHandler
    pendingChanges: boolean
    onDiscardChangesClick: React.MouseEventHandler
}

export const ManageProfilePageTemplate: FC<ManageProfilePageTemplateProps> = (
    props: ManageProfilePageTemplateProps
) => {
    const profilePictureFormRef = useRef<HTMLDivElement>(null)
    const mdScreens = useWindowSizeUp('md')
    const { classes: localClasses } = useLocalStyles()
    const containerRef = useRef(null)

    const mdElement = useElementWidthUp(containerRef, 'md')

    const ChangeCTAs = useMemo(
        () => (
            <div className={localClasses.changesCtas}>
                <Button
                    disabled={!props.pendingChanges}
                    variant="outlined"
                    color="secondary"
                    onClick={props.onDiscardChangesClick}
                    key="discard"
                >
                    Discard Changes
                </Button>
                <Button
                    disabled={!props.pendingChanges}
                    variant="contained"
                    color="primary"
                    onClick={props.onSaveChangesClick}
                    key="save-changes"
                >
                    Save Changes
                </Button>
            </div>
        ),
        [
            localClasses.changesCtas,
            props.onDiscardChangesClick,
            props.onSaveChangesClick,
            props.pendingChanges,
        ]
    )

    const scrollToProfilePictureForm = useCallback(() => {
        profilePictureFormRef.current?.scrollIntoView({ behavior: 'smooth' })
        profilePictureFormRef.current?.classList.add('highlighted')
        setTimeout(() => {
            profilePictureFormRef.current?.classList.remove('highlighted')
        }, 2000)
    }, [])

    return (
        <div className={localClasses.container} ref={containerRef}>
            <div className={localClasses.pageTitle}>
                <Typography variant={mdScreens ? 'h2' : 'h3'} component="h1">
                    Edit Profile
                </Typography>
                <Typography variant="body1">
                    Make changes to your ResiliencePoint profile here.
                </Typography>
                <Typography variant="body2" color={colors.primary.CERULEAN}>
                    Please note: if you would like to make changes to your
                    profile for a different organization, you must be logged in
                    to the organization that profile is connected to.
                </Typography>
            </div>
            <div
                className={classNames(localClasses.header, {
                    [localClasses.mdHeader]: mdElement,
                })}
            >
                <ProfileOverview
                    profile_picture_uri={props.profilePicture}
                    first_name={props.firstName}
                    last_name={props.lastName}
                    title={props.title}
                    isAdmin={props.isAdmin}
                    onChangeProfilePictureClick={scrollToProfilePictureForm}
                />
                {ChangeCTAs}
            </div>
            <ManageProfileView
                firstName={props.firstName}
                lastName={props.lastName}
                title={props.title}
                email={props.email}
                hazadapt_id_email={props.hazadapt_id_email}
                profilePicture={props.profilePicture}
                disableEmail={props.disableEmail}
                onFirstNameChange={props.onFirstNameChange}
                onLastNameChange={props.onLastNameChange}
                onTitleChange={props.onTitleChange}
                onEmailChange={props.onEmailChange}
                onImportClick={props.onImportClick}
                onChangeProfilePicture={props.onChangeProfilePicture}
                avatarOptions={props.avatarOptions}
                pendingChanges={props.pendingChanges}
                mode="manage"
                profilePictureFormRef={profilePictureFormRef}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
    },
    header: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '1rem 2rem',
        padding: '0 0 2rem',
        alignItems: 'flex-start',
    },
    mdHeader: {
        gridTemplateColumns: '1fr auto',
    },
    pageTitle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    changesCtas: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    welcomeText: {
        display: 'flex',
        padding: '2rem 0 1rem',
    },
})
