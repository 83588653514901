import { FC, useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import {
    insightLoadingBlurbsInitial,
    insightLoadingBlurbs7Sec,
    insightLoadingBlurbs15Sec,
} from '../../lib/entities'
import { randomlyPickOne } from '../../lib/utils'
import { makeStyles } from 'tss-react/mui'
import { colors } from '@hazadapt-git/public-core-base'

interface InsightsLoadingOverlayProps {}

export const InsightsLoadingOverlay: FC<InsightsLoadingOverlayProps> = () => {
    const { classes: localClasses } = useLocalStyles()
    const [blurb, setBlurb] = useState<string>(
        randomlyPickOne(insightLoadingBlurbsInitial) ?? ''
    )

    useEffect(() => {
        let secondTimeoutId: NodeJS.Timeout | undefined
        const timeoutId = setTimeout(() => {
            setBlurb(randomlyPickOne(insightLoadingBlurbs7Sec))
            secondTimeoutId = setTimeout(() => {
                setBlurb(randomlyPickOne(insightLoadingBlurbs15Sec))
            }, 7500)
        }, 7500)

        return () => {
            clearTimeout(timeoutId)
            if (secondTimeoutId) clearTimeout(secondTimeoutId)
        }
    }, [])

    return (
        <div className={localClasses.overlay}>
            <div className={localClasses.content}>
                <CircularProgress size="1.5rem" />
                <Typography fontWeight={500} textAlign="center">
                    {blurb}
                </Typography>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundColor: `${colors.grays.THUNDERCLOUD}78`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
    },
    content: {
        backgroundColor: colors.grays.WHITESMOKE,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
        padding: '1rem',
        borderRadius: '0.5rem',
        width: `min(80vh, 20rem)`,
    },
})
