import IconButton from '@mui/material/IconButton'
import { IoAdd, IoRemove } from 'react-icons/io5'
import {
    ChangeEventHandler,
    FC,
    FocusEventHandler,
    useEffect,
    useState,
} from 'react'
import { makeStyles } from 'tss-react/mui'
import voca from 'voca'
import { primaryIconSize } from '../../lib/styles/universal'
import { TextField } from './TextField'

interface CounterProps {
    value?: number
    min?: number
    max?: number
    onChange?(value: number): void | Promise<void>
}

export const Counter: FC<CounterProps> = (props: CounterProps) => {
    const { classes: localClasses } = useLocalStyles()

    const [tempValue, setTempValue] = useState<number | undefined>(props.value)

    useEffect(() => setTempValue(props.value), [props.value])

    const propagateChange = (value: number) => {
        props.onChange?.(value)
        setTempValue(value)
    }

    const onDecrement = () => {
        if (props.value === undefined) {
            propagateChange(0)
            return
        }
        if (props.min !== undefined && props.value - 1 < props.min) return
        propagateChange(props.value - 1)
    }

    const onIncrement = () => {
        if (props.value === undefined) {
            propagateChange(1)
            return
        }
        if (props.max !== undefined && props.value + 1 > props.max) return
        propagateChange(props.value + 1)
    }

    const onManualChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.currentTarget.value.length === 0) {
            setTempValue(undefined)
            return
        }
        if (
            e.currentTarget.value.length > 0 &&
            !voca.isNumeric(e.currentTarget.value)
        )
            return
        setTempValue(parseInt(e.currentTarget.value))
    }

    const saveChange: FocusEventHandler<HTMLInputElement> = (e) => {
        if (e.currentTarget.value.length === 0) {
            propagateChange(0)
            return
        }
        if (
            e.currentTarget.value.length > 0 &&
            !voca.isNumeric(e.currentTarget.value)
        )
            return
        const value = parseInt(e.currentTarget.value)
        if (props.min !== undefined && value < props.min)
            propagateChange(props.min)
        else if (props.max !== undefined && value > props.max)
            propagateChange(props.max)
        else propagateChange(value)
    }

    return (
        <div className={localClasses.container}>
            <IconButton
                onClick={onDecrement}
                disabled={
                    props.value !== undefined &&
                    props.min !== undefined &&
                    props.value <= props.min
                }
                color="secondary"
            >
                <IoRemove size={primaryIconSize} />
            </IconButton>
            <TextField
                type="number"
                value={tempValue ?? ''}
                onChange={onManualChange}
                onBlur={saveChange}
                inputProps={{
                    min: props.min,
                    max: props.max,
                }}
                variant="outlined"
            />
            <IconButton
                onClick={onIncrement}
                disabled={
                    props.value !== undefined &&
                    props.max !== undefined &&
                    props.value >= props.max
                }
                color="secondary"
            >
                <IoAdd size={primaryIconSize} />
            </IconButton>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
    },
})
