import { MultiCounterInsight, colors } from '@hazadapt-git/public-core-base'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { AspectRatio } from 'react-aspect-ratio'

export interface MultiCounterInsightViewProps
    extends Omit<MultiCounterInsight, 'headline'> {
    headline: React.ReactNode
}

export const MultiCounterInsightView: FC<MultiCounterInsightViewProps> = (
    props: MultiCounterInsightViewProps
) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div className={localClasses.outerWrapper}>
            <Grid container spacing={3}>
                {props.counters.map((item, index) => (
                    <Grid item xs={6} xl={4} key={`counter-item-${index + 1}`}>
                        {item.viewConfig ? (
                            <>
                                <div
                                    className={localClasses.counterTextWithView}
                                >
                                    <Typography
                                        variant="h4"
                                        color={colors.primary.CERULEAN}
                                    >
                                        {index + 1}.
                                    </Typography>
                                    <Typography
                                        color={colors.secondary.CETACEAN}
                                    >
                                        {item.counterText}
                                    </Typography>
                                </div>
                                <AspectRatio
                                    ratio={1}
                                    style={{ width: '100%' }}
                                >
                                    <div
                                        className={localClasses.counterView}
                                        style={{
                                            backgroundColor:
                                                item.viewConfig.backgroundColor,
                                        }}
                                    >
                                        <img
                                            src={
                                                item.viewConfig.icon.url ??
                                                item.viewConfig.icon.src
                                            }
                                            alt={item.viewConfig.icon.alt}
                                            className={
                                                localClasses.counterViewIcon
                                            }
                                        />
                                        <Typography fontWeight={500}>
                                            {item.label}
                                        </Typography>
                                    </div>
                                </AspectRatio>
                            </>
                        ) : (
                            <Typography variant="h3">
                                {index + 1}. {item.counterText}
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    outerWrapper: {
        margin: '0 -1rem 2rem',
        padding: '0 1rem',
        overflowY: 'auto',
        aspectRatio: 1,
    },
    counterView: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        borderRadius: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
    },
    counterViewIcon: {
        height: '3rem',
        width: 'auto',
        maxWidth: '100%',
        objectFit: 'contain',
    },
    counterTextWithView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
})
