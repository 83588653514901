import {
    AddOn,
    Cart,
    CheckoutConfigDTO,
    GetSubscriptionsDTO,
} from '@hazadapt-git/public-core-base'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { StripeElementsOptions } from '@stripe/stripe-js'
import { RootState } from '../store'
import Stripe from 'stripe'
import { createSelector } from 'reselect'
export interface SerializableCart
    extends Omit<Cart, 'date_created' | 'date_updated'> {
    date_created: string
    date_updated: string
}

export interface PaymentStoreState {
    cart?: SerializableCart
    addons: AddOn[]
    pendingSubscription?: Stripe.Subscription
    subscriptions?: Stripe.Subscription[]
    stripeOptions?: StripeElementsOptions
    checkoutNote?: string
    customer?: Stripe.Customer
    paymentMethods: Stripe.PaymentMethod[]
    buySeatsDrawerOpen: boolean
}

const INITIAL_PAYMENT_STORE_STATE: PaymentStoreState = {
    addons: [],
    buySeatsDrawerOpen: false,
    paymentMethods: [],
}

const paymentSlice = createSlice({
    name: 'payment',
    initialState: INITIAL_PAYMENT_STORE_STATE,
    reducers: {
        setAddons: (state, action: PayloadAction<AddOn[]>) => {
            state.addons = action.payload
        },
        setStripeOptions: (state, action: PayloadAction<CheckoutConfigDTO>) => {
            state.pendingSubscription = action.payload.subscription
            state.stripeOptions = {
                clientSecret: action.payload.client_secret ?? undefined,
            }
            state.checkoutNote = action.payload.note
        },
        resetStripeOptions: (state) => {
            state.pendingSubscription = undefined
            state.stripeOptions = undefined
            state.checkoutNote = undefined
        },
        updateCart: (state, action: PayloadAction<SerializableCart>) => {
            state.cart = {
                ...action.payload,
            }
        },
        setSubscriptions: (
            state,
            action: PayloadAction<GetSubscriptionsDTO>
        ) => {
            state.subscriptions = action.payload.subscriptions
            state.customer = action.payload.customer
            state.paymentMethods = action.payload.payment_methods
        },
        setBuySeatsDrawerOpen: (state, action: PayloadAction<boolean>) => {
            state.buySeatsDrawerOpen = action.payload
        },
    },
})

const getPaymentState = (state: RootState) => state.payment
const getCartState = (state: RootState) => state.payment.cart

export const selectCart = createSelector(
    [getCartState],
    (cart): Cart | undefined =>
        cart
            ? {
                  ...cart,
                  date_created: new Date(cart.date_created),
                  date_updated: new Date(cart.date_updated),
              }
            : undefined
)
export const selectSubscriptions = createSelector(
    [getPaymentState],
    (payment) =>
        ({
            customer: payment.customer,
            subscriptions: payment.subscriptions,
            payment_methods: payment.paymentMethods,
        } as GetSubscriptionsDTO)
)
export const {
    setAddons,
    setStripeOptions,
    resetStripeOptions,
    updateCart,
    setSubscriptions,
    setBuySeatsDrawerOpen,
} = paymentSlice.actions
export default paymentSlice.reducer
