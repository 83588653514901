import React, { FC } from 'react'
import {
    LineGraphInsight,
    LineGraphPoint,
} from '@hazadapt-git/public-core-base'
import { Line } from 'react-chartjs-2'
import { ChartData } from 'chart.js/dist/types/index'
import { addDays, differenceInMonths } from 'date-fns'
import { format } from 'date-fns-tz'

export interface LineGraphInsightViewProps
    extends Omit<LineGraphInsight, 'headline'> {
    headline: React.ReactNode
}

const msToLabels = (points: LineGraphPoint<number>[]): string[] => {
    const start_date = new Date(Math.min(...points.map((p) => p.label)))
    const end_date = new Date(Math.max(...points.map((p) => p.label)))
    const labels: string[] = []
    const dateDiff = differenceInMonths(end_date, start_date)
    for (const point of points) {
        const date = new Date(point.label)
        if (dateDiff >= 24) {
            // year
            labels.push(format(date, 'yyyy'))
        } else if (dateDiff >= 6) {
            // month
            labels.push(format(date, 'MMM yyyy'))
        } else if (dateDiff >= 2) {
            // week
            labels.push(
                `${format(date, 'MMM d')} - ${format(
                    addDays(date, 6),
                    'MMM d'
                )}`
            )
        } else {
            // day
            labels.push(`${format(date, 'MMM d')}`)
        }
    }
    return labels
}

export const LineGraphInsightView: FC<LineGraphInsightViewProps> = (
    props: LineGraphInsightViewProps
) => {
    const getLineGraphData = (): ChartData<'line', number[], string> => {
        return {
            labels: msToLabels(props.points),
            datasets: props.lines.map((line) => ({
                label: line.label,
                data: line.values,
                backgroundColor: line.color,
                borderColor: line.color,
            })),
        }
    }

    return (
        <Line
            data={getLineGraphData()}
            options={{
                resizeDelay: 50,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: props.axes.x,
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: props.axes.y,
                        },
                        ticks: {
                            precision: 0,
                        },
                        beginAtZero: true,
                    },
                },
                aspectRatio: 1,
                // maintainAspectRatio: false,
            }}
        />
    )
}
