import React, { FC } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ConfirmProfilePictureModal } from './ConfirmProfilePictureModal'
import { toBase64, updateProfilePicture } from '../../lib/utils'
import { ReactCropperElement } from 'react-cropper'
import { acceptedImageTypes } from '../../lib/entities'
import { theme } from '../../lib/styles/universal'
import { LoadingView } from '../molecules'
import { RootState, useAppSelector } from '../../lib/store'

interface ProfilePictureFormProps {
    profilePicture?: string
    onImportClick: React.MouseEventHandler
    onChangeProfilePicture(image?: string): void
    presets: string[]
}

export const ProfilePictureForm: FC<ProfilePictureFormProps> = (
    props: ProfilePictureFormProps
) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const cropperRef = React.useRef<ReactCropperElement>(null)
    const { classes: localClasses } = useLocalStyles()

    const [confirmProfilePictureModalOpen, setConfirmProfilePictureModalOpen] =
        React.useState<boolean>(false)
    const [wipProfilePicture, setWipProfilePicture] = React.useState<
        string | undefined
    >(props.profilePicture)

    const user = useAppSelector((state: RootState) => state.base.user)

    React.useEffect(() => {
        setWipProfilePicture(props.profilePicture)
    }, [props.profilePicture])

    const handleProfilePictureUpload: React.ChangeEventHandler<
        HTMLInputElement
    > = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0]
        const fileStr = await toBase64(file)
        if (!fileStr) return
        setWipProfilePicture(fileStr)
        setConfirmProfilePictureModalOpen(true)
    }

    const handleUploadPhotoClick = () => {
        if (!inputRef.current) return
        inputRef.current.click()
    }

    const clearProfilePictureInput = () => {
        if (!inputRef.current) return
        inputRef.current.value = ''
    }

    const onCloseConfirmProfilePictureModal = () => {
        clearProfilePictureInput()
        setWipProfilePicture(props.profilePicture)
        setConfirmProfilePictureModalOpen(false)
    }

    const onSaveProfilePicture = async () => {
        cropperRef.current?.cropper
            .getCroppedCanvas({ fillColor: 'white' })
            .toBlob((blob) => {
                if (!blob) return
                const file = new File([blob], `${user?.rp_user_id}`, {
                    type: 'image/jpeg',
                })
                updateProfilePicture(file)
            })
        const data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
        setWipProfilePicture(data)
        setConfirmProfilePictureModalOpen(false)
        clearProfilePictureInput()
    }

    const onAvatarSelect = (preset: string) => {
        setWipProfilePicture(preset)
        props.onChangeProfilePicture(preset)
    }

    const profilePictureStyle: SxProps<Theme> = {
        height: '8rem',
        width: '8rem',
    }
    const avatarStyle: SxProps<Theme> = { height: '6rem', width: '6rem' }

    return (
        <div className={localClasses.container}>
            <Typography variant="h4">Profile Picture</Typography>
            <Avatar
                src={wipProfilePicture || props.profilePicture}
                sx={profilePictureStyle}
            />
            <div className={localClasses.buttons}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUploadPhotoClick}
                    key="upload"
                >
                    Upload Photo
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={props.onImportClick}
                    key="import"
                >
                    Import from HazAdapt ID
                </Button>
            </div>
            <Divider sx={{ margin: 0 }}>
                <Typography variant="h4">OR</Typography>
            </Divider>
            <Typography variant="body1">Pick an Avatar</Typography>
            <div className={localClasses.avatarChoices}>
                {props.presets.length > 0 ? (
                    <>
                        {props.presets.map((preset, index) => (
                            <IconButton
                                key={`preset-${index + 1}`}
                                onClick={() => onAvatarSelect(preset)}
                            >
                                <Avatar src={preset} sx={avatarStyle} />
                            </IconButton>
                        ))}
                    </>
                ) : (
                    <LoadingView fullscreen={false} />
                )}
            </div>
            <input
                hidden
                ref={inputRef}
                type="file"
                onChange={handleProfilePictureUpload}
                accept={acceptedImageTypes}
            />
            <ConfirmProfilePictureModal
                selectedImage={wipProfilePicture}
                open={confirmProfilePictureModalOpen}
                onClose={onCloseConfirmProfilePictureModal}
                onImageChange={setWipProfilePicture}
                onSaveClick={onSaveProfilePicture}
                ref={cropperRef}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        [theme.breakpoints.up('xl')]: {
            flexDirection: 'row',
        },
    },
    avatarChoices: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '1rem',
    },
})
