import { debounce } from 'debounce'
import {
    FC,
    MouseEvent,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import {
    MapInsight,
    colors,
    InsightConfig,
    MapInsightItem,
} from '@hazadapt-git/public-core-base'
import {
    Layer,
    Map,
    MapRef,
    NavigationControl,
    Source,
    ViewState,
    ViewStateChangeEvent,
} from 'react-map-gl'
import mapboxgl, {
    MapboxEvent,
    MapLayerMouseEvent,
    MapLayerTouchEvent,
    MapWheelEvent,
    Popup,
} from 'mapbox-gl'
import Color from 'color'
import { makeStyles } from 'tss-react/mui'
import { Markdown } from '../atoms'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import NightShelterOutlinedIcon from '@mui/icons-material/NightShelterOutlined'
import HailIcon from '@mui/icons-material/Hail'
import {
    IoCheckmark,
    IoContract,
    IoEllipsisVertical,
    IoExpand,
    IoEyedrop,
} from 'react-icons/io5'
import { BBox, BBox2d } from '@turf/helpers/dist/js/lib/geojson'
import classNames from 'classnames'
import { Box } from '@mui/system'
import { useWindowSizeUp } from '../../lib/utils'
import { prepCheckColor, theme } from '../../lib/styles/universal'
import tinycolor from 'tinycolor2'
import { MapThemePickerModal } from './MapThemePickerModal'
import { RootState, useAppSelector } from '../../lib/store'
import bboxPolygon from '@turf/bbox'
import { ToggleButtons } from './ToggleButtons'

export interface MapInsightViewProps extends Omit<MapInsight, 'headline'> {
    headline: ReactNode
    defaultZoom?: number
    defaultCenter?: number[]
    defaultSelectedAreas?: string[]
    onUpdate(
        placement_id: string,
        bbox: BBox2d,
        zoom: number,
        selectedAreas?: Set<string>,
        reset?: boolean
    ): Promise<void>
    className?: string
    config: InsightConfig
    onSwitchVariant: (variant: string) => void | Promise<void>
    onSwitchColorTheme: (theme: string) => void
    onSwitchMode: (mode: string) => void | Promise<void>
}

export const MapInsightView: FC<MapInsightViewProps> = ({
    defaultZoom,
    defaultCenter,
    defaultSelectedAreas = [],
    onSwitchColorTheme,
    onUpdate,
    className,
    config,
    onSwitchVariant,
    placement_id,
    onSwitchMode,
    ...data
}: MapInsightViewProps) => {
    const defaultZoomRef = useRef(defaultZoom)
    const defaultCenterRef = useRef(defaultCenter)
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const mapRef = useRef<MapRef>(null)
    const selectedTilesRef = useRef<Set<string>>(new Set(defaultSelectedAreas))
    const lastUpdated = useAppSelector(
        (state: RootState) => state.content.last_updated
    )

    // Create a popup, but don't add it to the map yet.
    const popup = useRef<{
        elem: Popup
        feature_id: string | null
    }>({
        elem: new Popup({
            closeButton: false,
            closeOnClick: false,
        }),
        feature_id: null,
    })

    const { classes: localClasses } = useLocalStyles()
    const { variant, theme, mode } = config.pickers

    const [viewState, setViewState] = useState<Partial<ViewState>>({
        longitude: defaultCenter?.[0],
        latitude: defaultCenter?.[1],
    })
    const [themePickerOpen, setThemePickerOpen] = useState<boolean>(false)
    const [temporaryTheme, setTemporaryTheme] = useState<string>(
        theme?.selected_option ?? colors.secondary.GREENSHEEN
    )
    const [loading, setLoading] = useState<boolean>(false)
    const [mapExpanded, setMapExpanded] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [activeVariant, setActiveVariant] = useState<string>(
        variant?.selected_option ?? ''
    )
    const [showOverlay, setShowOverlay] = useState<boolean>(false)
    const lgScreens = useWindowSizeUp('lg')
    const { desktopMenuOpen } = useAppSelector((state: RootState) => state.base)
    const mobileMenuOpen = Boolean(anchorEl)

    const handleMobileMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setAnchorEl(null)
    }

    const handleVariantChange = useCallback(
        async (selectedVariant: string) => {
            if (selectedVariant === activeVariant) return
            setActiveVariant(selectedVariant)
            setLoading(true)
            try {
                await onSwitchVariant(selectedVariant)
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [activeVariant, onSwitchVariant]
    )

    const handleModeChange = useCallback(
        async (selectedMode: string) => {
            if (selectedMode === mode.selected_option) return
            setLoading(true)
            try {
                await onSwitchMode(selectedMode)
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [onSwitchMode, mode?.selected_option]
    )

    const configurePopup = useCallback(
        (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            if (
                !e.features ||
                e.features.length === 0 ||
                !['Polygon', 'MultiPolygon'].includes(
                    e.features?.[0].geometry.type
                ) ||
                !e.features?.[0].properties?.label
            )
                return
            // Change the cursor style as a UI indicator.
            e.target.getCanvas().style.cursor = 'pointer'

            const rawContent = e.features[0].properties.label
            const content = document.createElement('div')
            content.innerHTML = renderToStaticMarkup(
                <Markdown content={rawContent} />
            )

            // Populate the popup and set its coordinates on the user's mouse location.
            popup.current.elem.remove()
            popup.current.elem
                .setLngLat(e.lngLat)
                .setDOMContent(content)
                .addTo(e.target)
            popup.current.feature_id = e.features[0].properties.id
        },
        []
    )

    const handleMouseMove = useCallback((e: MapLayerMouseEvent) => {
        popup.current.elem.setLngLat(e.lngLat)

        if (
            !e.features ||
            e.features.length === 0 ||
            !['Polygon', 'MultiPolygon'].includes(
                e.features?.[0].geometry.type
            ) ||
            !e.features?.[0].properties?.label
        )
            return
        if (popup.current.feature_id === e.features?.[0].properties.id) return

        const rawContent = e.features[0].properties.label
        const content = document.createElement('div')
        content.innerHTML = renderToStaticMarkup(
            <Markdown content={rawContent} />
        )
        popup.current.elem.setDOMContent(content)
        popup.current.feature_id = e.features[0].properties.id
    }, [])

    const clearPopup = useCallback(
        (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            e.target.getCanvas().style.cursor = ''
            popup.current.elem.remove()
            popup.current.feature_id = null
        },
        []
    )

    const fitMapToBounds = useCallback((bbox: BBox, requery = true) => {
        if (!mapRef.current) return
        mapRef.current.fitBounds(
            [
                [bbox[0] - 0.25, bbox[1] - 0.25], // fit to bound with a little padding
                [bbox[2] + 0.25, bbox[3] + 0.25], // fit to bound with a little padding
            ],
            undefined,
            { requery }
        )
    }, [])

    const updateMapContents = useCallback(
        async (map: mapboxgl.Map | MapRef, reset = false) => {
            if (loading) return
            const bounds = map.getBounds()
            const sw = bounds.getSouthWest()
            const ne = bounds.getNorthEast()
            const bbox = [sw.lng, sw.lat, ne.lng, ne.lat] as BBox2d
            const zoom = map.getZoom()
            setLoading(true)
            popup.current.elem.remove()
            popup.current.feature_id = null
            try {
                await onUpdate(
                    placement_id,
                    bbox,
                    zoom,
                    selectedTilesRef.current,
                    reset
                )
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [onUpdate, placement_id, loading]
    )

    const isBboxValid = useCallback(
        (bbox: (number | null)[]): bbox is number[] => {
            return bbox.every((coord) => typeof coord === 'number')
        },
        []
    )

    const updateMap = useCallback(() => {
        const bbox = config.bbox
        if (bbox && isBboxValid(bbox)) {
            fitMapToBounds(bbox, false)
        }
    }, [config.bbox, fitMapToBounds, isBboxValid])

    useEffect(() => {
        updateMap()
    }, [lastUpdated, updateMap])

    useEffect(() => {
        selectedTilesRef.current = new Set(defaultSelectedAreas)
    }, [defaultSelectedAreas])

    const handleMapMovement = useCallback(
        async (e: ViewStateChangeEvent & { requery?: boolean }) => {
            if (e.requery === false || loading) return // Specifically needs to check for false state, not for undefined
            updateMapContents(e.target)
        },
        [updateMapContents, loading]
    )

    const handleTileClick = useCallback(
        async (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            if (!mapRef.current) return
            if (
                !e.features ||
                e.features.length === 0 ||
                !['Polygon', 'MultiPolygon'].includes(
                    e.features?.[0].geometry.type
                )
            ) {
                if (selectedTilesRef.current.size === 0) {
                    // Clicking out when no tile is selected; do nothing
                    return
                }
                // Clicking out when a tile is selected; reset area spec on query
                selectedTilesRef.current.clear()
                updateMapContents(mapRef.current, true)
            } else {
                const tileName = (e.features[0].properties as MapInsightItem)
                    .name
                if (selectedTilesRef.current.has(tileName)) {
                    // Clicking on the tile that's already selected; do nothing
                    return
                }
                selectedTilesRef.current.clear()
                selectedTilesRef.current.add(tileName)
                const bbox = bboxPolygon(e.features[0])
                fitMapToBounds(bbox)
            }
        },
        [updateMapContents, fitMapToBounds]
    )

    const updateViewState = useCallback((e: ViewStateChangeEvent) => {
        setViewState(e.viewState)
    }, [])

    const observeResize = useCallback(
        (
            container: HTMLDivElement,
            previousDimensions: { height: number; width: number }
        ) => {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    const newHeight = entry.contentRect.height
                    const newWidth = entry.contentRect.width

                    if (
                        newHeight > previousDimensions.height ||
                        newWidth > previousDimensions.width
                    ) {
                        previousDimensions.height = newHeight
                        previousDimensions.width = newWidth
                        if (mapRef.current) {
                            mapRef.current.resize()
                        }
                    }
                }
            })

            resizeObserver.observe(container)

            return () => {
                resizeObserver.unobserve(container)
            }
        },
        []
    )

    useEffect(() => {
        const newViewState: Partial<ViewState> = {}
        if (defaultCenterRef.current) {
            newViewState.longitude = defaultCenterRef.current[0]
            newViewState.latitude = defaultCenterRef.current[1]
        }
        if (defaultZoomRef.current !== undefined) {
            newViewState.zoom = defaultZoomRef.current
        }
        setViewState((vs) => ({
            ...vs,
            ...newViewState,
        }))
    }, [])

    useEffect(() => {
        if (!mapContainerRef.current) return

        const mapContainer = mapContainerRef.current

        const cleanupChildObserver = observeResize(mapContainer, {
            height: mapContainer.clientHeight,
            width: mapContainer.clientWidth,
        })
        return cleanupChildObserver
    }, [observeResize])

    const expandOrCollapseMap = useCallback(() => {
        if (!mapContainerRef.current || !mapRef.current) return
        setMapExpanded((ex) => !ex)
        setTimeout(() => {
            if (!mapRef.current) return
            updateMapContents(mapRef.current)
        }, 400)
    }, [updateMapContents])

    const updateContentsIfWidthChanged = useMemo(
        () =>
            debounce(() => {
                if (mapRef.current) updateMapContents(mapRef.current)
            }, 250),
        [updateMapContents]
    )

    useEffect(() => {
        window.addEventListener('resize', updateContentsIfWidthChanged)
        return () => {
            window.removeEventListener('resize', updateContentsIfWidthChanged)
        }
    }, [updateContentsIfWidthChanged])

    useEffect(() => {
        if (!mapRef.current) return
        mapRef.current.resize()
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
        }, 300)
    }, [desktopMenuOpen])

    const handleMapLoad = useCallback(
        (e: MapboxEvent) => {
            // not sure this initial resize is 100% necessary, let's look into it when we do a performance audit
            e.target.resize()
            e.target.scrollZoom.disable()

            fitMapToBounds(
                data.map_config.bbox ?? bboxPolygon(data.map_config),
                false
            )
        },
        [data.map_config, fitMapToBounds]
    )

    const handleWheel = useCallback((e: MapWheelEvent) => {
        let overlayTimeout: NodeJS.Timeout | undefined
        e.target.on('wheel', (e) => {
            if (e.originalEvent.ctrlKey) {
                setShowOverlay(false)
                e.target.scrollZoom.enable()
                clearTimeout(overlayTimeout)
            } else {
                e.preventDefault()
                setShowOverlay(true)
                clearTimeout(overlayTimeout)
                overlayTimeout = setTimeout(() => {
                    setShowOverlay(false)
                }, 250)
            }
        })
    }, [])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.ctrlKey) {
                setShowOverlay(false)
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    return (
        <>
            {/* MAP HEADER */}
            <div
                className={localClasses.header}
                style={{
                    backgroundColor: placement_id.includes('prep-check')
                        ? prepCheckColor
                        : '#4088B4',
                }}
            >
                <Stack
                    direction={lgScreens ? 'row' : 'column'}
                    gap={lgScreens ? '1rem' : '.5rem'}
                >
                    <Typography className={localClasses.headerText}>
                        {data.headline}
                    </Typography>
                    {/* MODE SELECTOR */}
                    {mode && (
                        <ToggleButtons
                            ariaLabel={`Toggle Between ${mode.options[0].label} and ${mode.options[1].label} Map Data`}
                            leftLabel={mode.options[0].label}
                            rightLabel={mode.options[1].label}
                            leftIcon={<NightShelterOutlinedIcon />}
                            rightIcon={<HailIcon />}
                            onLeftClick={() =>
                                handleModeChange(mode.options[0].value)
                            }
                            onRightClick={() =>
                                handleModeChange(mode.options[1].value)
                            }
                        />
                    )}
                </Stack>
                {/* EXPAND ICON */}
                <Tooltip
                    title={`${mapExpanded ? 'Shrink' : 'Expand'} map`}
                    placement="top"
                    arrow
                >
                    <IconButton
                        onClick={expandOrCollapseMap}
                        sx={{
                            color: colors.grays.BLANC,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {mapExpanded ? <IoContract /> : <IoExpand />}
                    </IconButton>
                </Tooltip>
            </div>

            <div
                className={classNames(localClasses.mapContainer, className, {
                    [localClasses.expandedMapContainer]: mapExpanded,
                })}
                ref={mapContainerRef}
            >
                {/* MAP CONTROLS */}
                <div className={localClasses.mapControls}>
                    {theme && theme.options.length > 0 && (
                        <Box
                            className={localClasses.iconContainer}
                            sx={{ borderBottomRightRadius: '0.5rem' }}
                        >
                            <IconButton
                                onClick={() => setThemePickerOpen(true)}
                            >
                                <IoEyedrop
                                    color={colors.grays.NOIR}
                                    size="1rem"
                                />
                            </IconButton>
                        </Box>
                    )}
                    {variant?.options.length > 0 && (
                        <>
                            {lgScreens ? (
                                <div className={localClasses.variantContainer}>
                                    {variant.options.map((o) => {
                                        const backgroundColor =
                                            o.buttonColor ??
                                            colors.secondary.BONDI
                                        const borderColor =
                                            o.value === activeVariant
                                                ? Color(
                                                      o.buttonColor ??
                                                          colors.secondary.BONDI
                                                  )
                                                      .lighten(0.9)
                                                      .toString()
                                                : o.buttonColor ??
                                                  colors.secondary.BONDI
                                        const hoverBackgroundColor = Color(
                                            o.buttonColor ??
                                                colors.secondary.BONDI
                                        )
                                            .darken(0.1)
                                            .toString()
                                        const hoverBorderColor =
                                            o.value === activeVariant
                                                ? borderColor
                                                : hoverBackgroundColor
                                        return (
                                            <Button
                                                key={o.value}
                                                onClick={() =>
                                                    handleVariantChange(o.value)
                                                }
                                                className={
                                                    localClasses.variantOption
                                                }
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor:
                                                            hoverBackgroundColor,
                                                        borderColor:
                                                            hoverBorderColor,
                                                    },
                                                    backgroundColor:
                                                        backgroundColor,
                                                    borderColor: borderColor,
                                                }}
                                            >
                                                {o.label}
                                            </Button>
                                        )
                                    })}
                                </div>
                            ) : (
                                <Box
                                    className={localClasses.iconContainer}
                                    sx={{
                                        borderBottomLeftRadius: '0.5rem',
                                        marginLeft: 'auto',
                                    }}
                                >
                                    <IconButton onClick={handleMobileMenuOpen}>
                                        <IoEllipsisVertical
                                            color={colors.grays.NOIR}
                                            size="1rem"
                                        />
                                    </IconButton>
                                    <Menu
                                        id="map-insight-menu"
                                        anchorEl={anchorEl}
                                        open={mobileMenuOpen}
                                        onClose={handleMobileMenuClose}
                                    >
                                        {variant.options.map((o) => (
                                            <MenuItem
                                                key={o.value}
                                                onClick={() =>
                                                    handleVariantChange(o.value)
                                                }
                                            >
                                                {o.value === activeVariant ? (
                                                    <>
                                                        <ListItemIcon>
                                                            <IoCheckmark />
                                                        </ListItemIcon>
                                                        <span>{o.label}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <ListItemText inset>
                                                            {o.label}
                                                        </ListItemText>
                                                    </>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            )}
                        </>
                    )}
                </div>

                {/* MAP */}
                <Map
                    {...viewState}
                    ref={mapRef}
                    mapLib={import('mapbox-gl')}
                    projection={{ name: 'mercator' }}
                    mapStyle="mapbox://styles/mapbox/light-v11"
                    style={{ height: '100%' }}
                    onLoad={handleMapLoad}
                    onMove={updateViewState}
                    interactiveLayerIds={data.map_config.features.flatMap(
                        (tile) => [
                            `${tile.properties.id}--line`,
                            `${tile.properties.id}--fill`,
                        ]
                    )}
                    onClick={handleTileClick}
                    onDragEnd={handleMapMovement}
                    onZoomEnd={handleMapMovement}
                    reuseMaps
                    onMouseEnter={configurePopup}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={clearPopup}
                    onTouchEnd={configurePopup}
                    onTouchCancel={clearPopup}
                    onWheel={handleWheel}
                    touchPitch={false}
                    pitchWithRotate={false}
                    pitch={0}
                >
                    <NavigationControl
                        style={{
                            marginTop:
                                lgScreens ||
                                !variant ||
                                variant.options.length === 0
                                    ? '0.5625rem'
                                    : '3.75rem',
                        }}
                    />
                    {data.map_config.features.map((tile) => (
                        <Source
                            id={tile.properties.id}
                            type="geojson"
                            data={tile}
                            key={tile.properties.id}
                        >
                            <Layer
                                id={`${tile.properties.id}--fill`}
                                type="fill"
                                source={tile.properties.id}
                                paint={{
                                    'fill-color':
                                        tile.properties.value > 0
                                            ? tinycolor(temporaryTheme)
                                                  .lighten(
                                                      tile.properties.lightening
                                                  )
                                                  .toRgbString()
                                            : tinycolor(colors.grays.CUMULUS)
                                                  .lighten(25)
                                                  .toRgbString(),
                                    'fill-opacity': 0.67,
                                }}
                            />
                            <Layer
                                id={`${tile.properties.id}--line`}
                                type="line"
                                source={tile.properties.id}
                                paint={{
                                    'line-color': colors.grays.NIMBUS,
                                    'line-width': 1,
                                }}
                            />
                        </Source>
                    ))}
                </Map>

                {/* LOADING AND HOTKEY OVERLAYS */}
                {loading || showOverlay ? (
                    <div
                        className={localClasses.overlayContainer}
                        style={
                            showOverlay ? { pointerEvents: 'none' } : undefined
                        }
                    >
                        <div className={localClasses.overlayContent}>
                            {loading && (
                                <CircularProgress size="1rem" color="primary" />
                            )}
                            <Typography fontWeight={500}>
                                {loading
                                    ? 'Loading...'
                                    : "Hold 'Control' and scroll to zoom"}
                            </Typography>
                        </div>
                    </div>
                ) : null}
            </div>
            <MapThemePickerModal
                open={themePickerOpen}
                onClose={() => setThemePickerOpen(false)}
                themeConfig={theme}
                selectedTheme={temporaryTheme}
                setTheme={setTemporaryTheme}
                onSaveThemeChange={() => onSwitchColorTheme(temporaryTheme)}
            />
        </>
    )
}

const useLocalStyles = makeStyles()({
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '4rem',
        padding: '.5rem 1rem',
    },
    headerText: {
        alignItems: 'center',
        color: colors.grays.BLANC,
        display: 'flex',
        flexWrap: 'wrap',
        fontWeight: 500,
        whiteSpace: 'break-spaces',
    },
    mapContainer: {
        height: '15rem',
        position: 'relative',
        transition: 'all .3s ease-in-out',
        [theme.breakpoints.up('md')]: {
            height: '20rem',
        },
        [theme.breakpoints.up('xl')]: {
            height: '25rem',
        },
    },
    expandedMapContainer: {
        height: '30rem',
        [theme.breakpoints.up('md')]: {
            height: '40rem',
        },
        [theme.breakpoints.up('xl')]: {
            height: '50rem',
        },
    },
    map: {
        height: '100%',
    },
    mapControls: {
        alignItems: 'flex-start',
        display: 'flex',
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    },
    iconContainer: {
        backgroundColor: colors.grays.BLANC,
        boxShadow: '0 0.25rem 0.5rem rgba(0,0,0,0.16)',
        padding: '.5rem',
        pointerEvents: 'all',
        top: 0,
    },
    variantContainer: {
        display: 'flex',
        gap: '.5rem',
        marginLeft: 'auto',
        marginRight: '2.625rem',
        padding: '.5rem',
    },
    variantOption: {
        borderRadius: '.5rem',
        borderStyle: 'solid',
        borderWidth: 3,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        minWidth: '10rem',
        padding: '.5rem',
        pointerEvents: 'all',
    },
    overlayContainer: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 2,
    },
    overlayContent: {
        alignItems: 'center',
        backgroundColor: colors.grays.BLANC,
        borderRadius: '0.5rem',
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center',
        padding: '1rem',
    },
})
