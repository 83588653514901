import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import React, { FC, ReactNode, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { colors } from '@hazadapt-git/public-core-base'

interface ToggleButtonsProps {
    ariaLabel: string
    leftLabel: string
    rightLabel: string
    onLeftClick(): void
    onRightClick(): void
    leftIcon?: ReactNode
    rightIcon?: ReactNode
    selectedBkgdColor?: string
    selectedTextColor?: string
    unSelectedTextColor?: string
}

export const ToggleButtons: FC<ToggleButtonsProps> = ({
    ariaLabel,
    leftLabel,
    rightLabel,
    onLeftClick,
    onRightClick,
    leftIcon,
    rightIcon,
    selectedBkgdColor = colors.grays.BLANC,
    selectedTextColor = colors.grays.NOIR,
    unSelectedTextColor = colors.grays.BLANC,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const [isLeftSelected, setIsLeftSelected] = useState<boolean>(true)

    const handleLeftButtonClick = () => {
        if (isLeftSelected) return
        setIsLeftSelected(true)
        onLeftClick()
    }

    const handleRightButtonClick = () => {
        if (!isLeftSelected) return
        setIsLeftSelected(false)
        onRightClick()
    }

    const renderButton = (
        label: string,
        icon: ReactNode,
        onClick: () => void,
        isSelected: boolean
    ) => {
        return (
            <Button
                aria-label={`Toggle to ${label}`}
                aria-pressed={isSelected}
                className={localClasses.modeButton}
                startIcon={icon}
                onClick={onClick}
                disableTouchRipple
                sx={{
                    color: isSelected ? selectedTextColor : unSelectedTextColor,
                }}
            >
                {label}
            </Button>
        )
    }

    return (
        <Box
            className={localClasses.modeSwitchContainer}
            role="group"
            aria-label={ariaLabel}
        >
            <div
                className={localClasses.modeBackground}
                style={{
                    backgroundColor: selectedBkgdColor,
                    transform: isLeftSelected
                        ? 'translateX(0)'
                        : 'translateX(100%)',
                }}
            />
            {renderButton(
                leftLabel,
                leftIcon,
                handleLeftButtonClick,
                isLeftSelected
            )}
            {renderButton(
                rightLabel,
                rightIcon,
                handleRightButtonClick,
                !isLeftSelected
            )}
        </Box>
    )
}

const useLocalStyles = makeStyles()({
    modeSwitchContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '5rem',
        display: 'flex',
        maxWidth: 'fit-content',
        position: 'relative',
    },
    modeButton: {
        backgroundColor: 'transparent',
        position: 'relative',
        transition: 'color 0.3s ease',
        whiteSpace: 'nowrap',
        width: '50%',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        '&:active': {
            backgroundColor: 'transparent',
        },
    },
    modeBackground: {
        borderRadius: '5rem',
        bottom: 0,
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.35)',
        left: 0,
        position: 'absolute',
        top: 0,
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        width: '50%',
    },
})
