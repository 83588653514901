import { colors } from '@hazadapt-git/public-core-base'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { customHazTealColor } from '../lib/styles/universal'
import { makeStyles } from 'tss-react/mui'
import { getEnvVars } from '../lib/config'
import { canUserEditOrganization, isUserAdmin } from '../lib/utils'
import { NamespacedPageProps } from '../lib/entities'
import {
    Navigate,
    Route,
    RouteProps,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router'
import { ManageProfilePage } from './ManageProfile'
import { ManageOrganizationPage } from './ManageOrganization'
import { IoOpenOutline } from 'react-icons/io5'
import { SubscriptionsAndBillingPage } from './SubscriptionsAndBilling'

const { authUrl } = getEnvVars()

const SettingsPage: FC<NamespacedPageProps> = ({
    user,
    organization,
    loading,
}: NamespacedPageProps) => {
    const location = useLocation()
    const navigate = useNavigate()
    const activeNav = location.pathname
    const { classes: localClasses } = useLocalStyles()

    const [activeTab, setActiveTab] = React.useState<string>(
        activeNav.substring(1).split('/').slice(1).join('/') || 'profile'
    )

    React.useEffect(() => {
        document.title = 'Settings - ResiliencePoint'
    }, [])

    useEffect(() => {
        if (activeNav === '/settings') {
            navigate('/settings/profile')
        } else if (activeNav.includes('organization')) {
            setActiveTab('organization')
        } else if (activeNav.includes('subscriptions')) {
            setActiveTab('subscriptions')
        } else {
            setActiveTab(
                activeNav.substring(1).split('/').slice(1).join('/') ||
                    'profile'
            )
        }
    }, [activeNav, navigate])

    const handleTabChange = useCallback(
        (nv: string) => {
            if (nv === 'hazadapt-id') return
            navigate(`/settings/${nv}`)
        },
        [navigate]
    )

    const routes: RouteProps[] = useMemo(() => {
        return [
            {
                path: 'profile',
                element: <ManageProfilePage user={user} loading={loading} />,
            },
            {
                path: 'organization/*',
                element: (
                    <ManageOrganizationPage user={user} loading={loading} />
                ),
            },
            {
                path: 'subscriptions/*',
                element: (
                    <SubscriptionsAndBillingPage
                        user={user}
                        loading={loading}
                    />
                ),
            },
            { path: '*', element: <Navigate replace to="/settings" /> },
        ]
    }, [loading, user])

    return (
        <Box className={localClasses.container}>
            <img
                src={organization?.logo?.src || organization?.logo?.url}
                alt={`${organization?.name} logo`}
                className={localClasses.orgLogo}
            />
            <Tabs
                value={activeTab.split('/')[0]}
                onChange={(e, nv: string) => handleTabChange(nv)}
                aria-label="settings"
                TabIndicatorProps={{ sx: { display: 'none' } }}
                className={localClasses.tabs}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab
                    label="Edit Profile"
                    value="profile"
                    className={localClasses.tab}
                />
                <Tab
                    label="HazAdapt ID"
                    icon={<IoOpenOutline />}
                    href={authUrl}
                    target="_blank"
                    value="hazadapt-id"
                    className={localClasses.tab}
                />
                <Tab
                    label={
                        canUserEditOrganization(user)
                            ? 'Organization Settings'
                            : 'My Organization'
                    }
                    value="organization"
                    className={localClasses.tab}
                />
                {isUserAdmin(user) ? (
                    <Tab
                        label="Subscriptions & Billing"
                        value="subscriptions"
                        className={localClasses.tab}
                    />
                ) : null}
            </Tabs>
            <Routes>
                {routes.map((route: RouteProps) => (
                    <Route key={route.path} {...route} />
                ))}
            </Routes>
        </Box>
    )
}

export default SettingsPage

const useLocalStyles = makeStyles()({
    container: {
        flex: 1,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '2rem',
    },
    orgLogo: { height: '4rem' },
    settingsNav: {
        display: 'flex',
        flexDirection: 'row',
    },
    textContainer: {
        paddingLeft: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
        gap: '0.5rem',
    },
    userTitle: {
        color: customHazTealColor,
        fontWeight: 600,
    },
    editButton: {
        color: colors.primary.BLUEBERRY,
        fontWeight: 600,
        padding: 0,
        margin: 0,
        textDecoration: 'none',
    },
    linkText: {
        color: colors.grays.CHARCOAL,
        textDecoration: 'none',
    },
    profilePicture: {
        height: 'min-content',
    },
    hidden: {
        display: 'none',
    },
    tabs: {
        borderBottom: `2px ${colors.primary.CERULEAN} solid`,

        minHeight: 'min-content',
        '.MuiButtonBase-root.MuiTabs-scrollButtons.Mui-disabled': {
            display: 'none',
        },
    },
    tab: {
        color: colors.grays.NOIR,
        fontSize: '1rem',
        lineHeight: '1.25rem',
        fontWeight: 'normal',
        textTransform: 'none',
        padding: '0.5rem 2.5rem 0.5rem 0.5rem',
        minHeight: '2rem',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        gap: '0.5rem',
        '&:not(:last-of-type)': {
            marginRight: '0.5rem',
        },
        '.MuiTab-iconWrapper': {
            margin: 0,
        },
        '&.Mui-selected': {
            backgroundColor: colors.softTones.BLUEBERRY,
            color: colors.grays.NOIR,
            borderBottom: 'none',
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
            fontWeight: '500',
        },
    },
})
