import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { SxProps, Theme } from '@mui/material'
import React, { FC } from 'react'
import { GroupViewType } from '../../lib/entities'
import { IoGrid, IoList } from 'react-icons/io5'
import { primaryIconSize } from '../../lib/styles/universal'
import { colors } from '@hazadapt-git/public-core-base'

interface GroupViewSwitchProps {
    state: GroupViewType
    onSwitch(state: GroupViewType): void
}

export const GroupViewSwitch: FC<GroupViewSwitchProps> = (
    props: GroupViewSwitchProps
) => {
    const buttonStyle = (state: GroupViewType): SxProps<Theme> => ({
        borderRadius: '0.5rem',
        display: 'flex',
        gap: '0.5rem',
        backgroundColor:
            props.state === state
                ? `${colors.softTones.BLUEBERRY}BB`
                : colors.grays.BLANC,
        borderColor:
            props.state === state
                ? colors.secondary.LINKY
                : colors.grays.NIMBUS,
        borderWidth: props.state === state ? 2 : 1,
        borderStyle: 'solid',
        color:
            props.state === state ? colors.secondary.LINKY : colors.grays.NOIR,
        ':hover': {
            backgroundColor:
                props.state === state
                    ? colors.softTones.BLUEBERRY
                    : colors.grays.WHITESMOKE,
        },
        width: '9rem',
        padding: '0.375rem 0.5rem',
        fontWeight: 'normal',
    })
    return (
        <ButtonGroup>
            <Button
                variant="contained"
                onClick={() => props.onSwitch('card')}
                sx={{
                    ...buttonStyle('card'),
                    borderRightColor:
                        props.state === 'card'
                            ? `${colors.secondary.LINKY} !important`
                            : undefined,
                    marginRight: props.state === 'card' ? '1px' : 0,
                }}
                color={props.state === 'card' ? 'primary' : 'secondary'}
            >
                <IoGrid size="1.125rem" />
                Card view
            </Button>
            <Button
                variant="contained"
                onClick={() => props.onSwitch('list')}
                sx={{
                    ...buttonStyle('list'),
                    borderLeftWidth: props.state === 'list' ? '2px' : 0,
                    marginLeft: 0,
                }}
                color={props.state === 'list' ? 'primary' : 'secondary'}
            >
                <IoList size={primaryIconSize} />
                List view
            </Button>
        </ButtonGroup>
    )
}
