import {
    OrganizationChangelogDTO,
    OrganizationWithMembers,
} from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'

import { OrganizationChangelogPageTemplate } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { getOrganizationChangelog } from '../lib/utils'
import { useSearchParams } from 'react-router-dom'

interface OrganizationChangelogPageProps
    extends Omit<NamespacedPageProps, 'organization'> {
    organization: OrganizationWithMembers
}

export const OrganizationChangelogPage: FC<OrganizationChangelogPageProps> = ({
    organization,
    user,
    loading,
}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [changelog, setChangelog] = React.useState<OrganizationChangelogDTO>()

    React.useEffect(() => {
        const page = parseInt(searchParams.get('page') ?? '1')
        getOrganizationChangelog(page).then(setChangelog).catch(console.error)
    }, [searchParams])

    React.useEffect(() => {
        document.title = 'Organization Changelog - ResiliencePoint'
    }, [])

    const onPaginate = (e: React.ChangeEvent, page: number) => {
        if (page < 1) return
        setChangelog(undefined)
        const params = new URLSearchParams(searchParams)
        params.set('page', page.toString())
        setSearchParams(params)
    }

    return (
        <OrganizationChangelogPageTemplate
            organization={organization}
            user={user}
            changes={changelog?.changes}
            page={
                changelog?.page ??
                parseInt(searchParams.get('page') ?? '1') ??
                1
            }
            num_pages={changelog?.num_pages ?? 0}
            onPaginate={onPaginate}
            loading={loading}
        />
    )
}
