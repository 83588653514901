import {
    Cart,
    CartAddition,
    CheckoutConfigDTO,
    Transaction,
} from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'
import { isa } from '../api'
import { store } from '../store'
import {
    SerializableCart,
    setStripeOptions,
    updateCart as updateCartDispatch,
} from '../slices/payment'
import { getEnvVars } from '../config'
const { rpUriBase } = getEnvVars()

export const getCart = async (): Promise<void> => {
    try {
        const response: AxiosResponse<SerializableCart> = await isa.get('/cart')
        store.dispatch(updateCartDispatch(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const updateCart = async (
    cart: Cart,
    items: CartAddition[],
    sync = false
): Promise<void> => {
    isa.put('/cart', items)
        .then((response: AxiosResponse<SerializableCart>) => {
            if (sync) store.dispatch(updateCartDispatch(response.data))
        })
        .catch(console.error)
    if (!sync) {
        for (const item of items) {
            const idx = cart.items.findIndex(
                (c) => c.stripe_product_id === item.stripe_product_id
            )
            if (idx >= 0) {
                cart.items[idx].quantity = item.quantity
            }
        }
        store.dispatch(
            updateCartDispatch({
                ...cart,
                date_created: cart.date_created.toISOString(),
                date_updated: cart.date_updated.toISOString(),
            })
        )
    }
}

export const removeItemFromCart = async (item_id: number): Promise<void> => {
    const response: AxiosResponse<SerializableCart> = await isa.delete(
        `/cart/${item_id}`
    )
    store.dispatch(
        updateCartDispatch({
            ...response.data,
            date_created: response.data.date_created,
            date_updated: response.data.date_updated,
        })
    )
}

export const clearCart = async (cart: Cart): Promise<void> => {
    await isa.delete('/cart')
    store.dispatch(
        updateCartDispatch({
            ...cart,
            items: [],
            date_created: cart.date_created.toISOString(),
            date_updated: cart.date_updated.toISOString(),
        })
    )
}

export const overrideCart = async (cart: Cart): Promise<void> => {
    store.dispatch(
        updateCartDispatch({
            ...cart,
            date_created: cart.date_created.toISOString(),
            date_updated: cart.date_updated.toISOString(),
        })
    )
    const response: AxiosResponse<SerializableCart> = await isa.put(
        '/cart/override',
        cart
    )
    store.dispatch(
        updateCartDispatch({
            ...response.data,
            date_created: response.data.date_created,
            date_updated: response.data.date_updated,
        })
    )
}

export const getStripePaymentIntent = async (): Promise<void> => {
    const response: AxiosResponse<CheckoutConfigDTO> = await isa.get(
        '/checkout'
    )
    if (response.status === 204) {
        window.location.href = `https://hazadapt-internal.${rpUriBase}/settings/organization`
    } else {
        store.dispatch(setStripeOptions(response.data))
    }
}

export const getReceipt = async (
    payment_intent_id: string
): Promise<Transaction> => {
    const response: AxiosResponse<Transaction> = await isa.get(
        `/receipt/${payment_intent_id}`
    )
    return response.data
}
