import Check from '@mui/icons-material/Check'
import DriveFileRenameOutline from '@mui/icons-material/DriveFileRenameOutline'
import Button from '@mui/material/Button'
import { ButtonProps } from '@mui/material'
import React, { FC } from 'react'

interface EditButtonProps extends ButtonProps {
    editOn: boolean
}

export const EditButton: FC<EditButtonProps> = (props: EditButtonProps) => {
    const { editOn, ...buttonProps } = props
    return (
        <Button
            variant="text"
            {...buttonProps}
            startIcon={editOn ? <Check /> : <DriveFileRenameOutline />}
        >
            <div style={{ textDecoration: 'underline' }}>
                {editOn ? 'Save' : 'Edit'}
            </div>
        </Button>
    )
}
