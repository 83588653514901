import { RPUser } from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'
import { Dialog } from '../atoms'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

interface TransferOrgOwnershipModalProps {
    user: RPUser
    open?: boolean
    onClose(): void
    onTransferOwnership(user: RPUser): void
}

export const TransferOrgOwnershipModal: FC<TransferOrgOwnershipModalProps> = ({
    user,
    open,
    onClose,
    onTransferOwnership,
}) => {
    const handleTransferOwnership = async (user: RPUser) => {
        onTransferOwnership(user)
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title="Transfer Ownership"
            content={
                <Typography variant="h5">
                    Are you sure you want to transfer ownership to{' '}
                    {user.first_name} {user.last_name}? You cannot undo this
                    action.
                </Typography>
            }
            ctas={[
                <Button
                    key="cancel-transfer"
                    onClick={onClose}
                    variant="outlined"
                >
                    Cancel
                </Button>,
                <Button
                    key="confirm-transfer"
                    onClick={() => handleTransferOwnership(user)}
                >
                    Confirm Transfer
                </Button>,
            ]}
        />
    )
}
