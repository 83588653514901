import {
    HazardOverviewResponse,
    PrepCheckOverviewDTO,
    TrendingHazardDTO,
} from '@hazadapt-git/public-core-base'
import { isaPublic, isa } from '../api'
import { AxiosResponse } from 'axios'
import { store } from '../store'
import {
    setHazards,
    setPrepChecks,
    setTrendingHazards,
} from '../slices/content'

export const getHazardData = async (): Promise<void> => {
    const { organization } = store.getState().base
    if (!organization) return
    const response: AxiosResponse<HazardOverviewResponse> = await isaPublic.get(
        `/v2/hazards?oid=${organization.id}`
    )
    store.dispatch(setHazards(response.data))
}

export const getTrendingHazards = async (): Promise<void> => {
    const { organization } = store.getState().base
    if (!organization) return

    const response: AxiosResponse<TrendingHazardDTO[]> = await isa.get(
        `/trending-hazards`
    )
    store.dispatch(setTrendingHazards(response.data))
}

export const getPrepCheckData = async (): Promise<void> => {
    const { organization } = store.getState().base
    if (!organization) return
    const response: AxiosResponse<PrepCheckOverviewDTO[]> = await isaPublic.get(
        `/prep-checks?oid=${organization.id}`
    )
    store.dispatch(setPrepChecks(response.data))
}
