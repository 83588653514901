import {
    HazardOverview,
    HazardOverviewResponse,
    PrepCheckOverviewDTO,
    TrendingHazardDTO,
} from '@hazadapt-git/public-core-base'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ContentStoreState {
    hazards: HazardOverview[]
    prepChecks: PrepCheckOverviewDTO[]
    trendingHazards: TrendingHazardDTO[]
    has_local_content: boolean
    last_updated: string
}

const INITIAL_CONTENT_STORE_STATE: ContentStoreState = {
    trendingHazards: [],
    hazards: [],
    prepChecks: [],
    has_local_content: false,
    last_updated: '',
}

const contentSlice = createSlice({
    name: 'content',
    initialState: INITIAL_CONTENT_STORE_STATE,
    reducers: {
        setHazards: (state, action: PayloadAction<HazardOverviewResponse>) => {
            state.hazards = [...action.payload.hazards]
            state.has_local_content = action.payload.has_local_content
        },
        setPrepChecks: (
            state,
            action: PayloadAction<PrepCheckOverviewDTO[]>
        ) => {
            state.prepChecks = [...action.payload]
        },
        setTrendingHazards: (
            state,
            action: PayloadAction<TrendingHazardDTO[]>
        ) => {
            state.trendingHazards = [...action.payload]
        },
        setLastUpdated: (state) => {
            state.last_updated = new Date().toISOString()
        },
    },
})

export const { setHazards, setPrepChecks, setTrendingHazards, setLastUpdated } =
    contentSlice.actions
export default contentSlice.reducer
