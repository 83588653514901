import React, { FC, useEffect } from 'react'
import { NamespacedPageProps } from '../lib/entities'
import { Navigate, Route, Routes } from 'react-router'
import { getSubscriptions } from '../lib/utils'
import { LoadingView } from '../components'
import { ManagePaymentInfoView } from '../components/organisms/ManagePaymentInfoView'
import { SubscriptionsOverview } from '../components/organisms/SubscriptionsOverview'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { customHazTealColor } from '../lib/styles/universal'
import { ManageSubscriptionsPage } from './ManageSubscriptions'
import { useOrganization } from '../lib/hooks/useOrganization'

interface SubscriptionsAndBillingPageProps extends NamespacedPageProps {}

export const SubscriptionsAndBillingPage: FC<
    SubscriptionsAndBillingPageProps
> = ({ user, loading }) => {
    const { organization } = useOrganization()

    useEffect(() => {
        getSubscriptions()
    }, [])

    React.useEffect(() => {
        document.title = 'Subscriptions and Billing - ResiliencePoint'
    }, [])

    if (!user || !organization) {
        return !loading && (user === null || organization === null) ? null : (
            <LoadingView />
        )
    }

    return (
        <Stack gap={'2rem'}>
            <Stack gap={'.5rem'} maxWidth={'34rem'}>
                <Typography variant="body1">
                    Make changes to your subscriptions or add seats here.
                </Typography>
                <Typography variant="body2" color={customHazTealColor}>
                    Please note if you would like to make changes to a different
                    organization, you must be logged in to the organization.
                </Typography>
            </Stack>
            <Routes>
                <Route
                    path=""
                    element={
                        <SubscriptionsOverview
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    }
                />
                <Route
                    path="payment"
                    element={
                        <ManagePaymentInfoView
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    }
                />
                <Route
                    path="core/*"
                    element={
                        <ManageSubscriptionsPage
                            subscriptionType="core"
                            organization={organization}
                        />
                    }
                />
                <Route
                    path="seats/*"
                    element={
                        <ManageSubscriptionsPage
                            subscriptionType="seat"
                            organization={organization}
                        />
                    }
                />
                <Route
                    path="*"
                    element={<Navigate replace to="/settings/subscriptions" />}
                />
            </Routes>
        </Stack>
    )
}
