import React, { useState } from 'react'
import Cropper, { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { useForwardedRef } from '../../lib/utils'

interface ImageCropperProps {
    selectedImage?: string
    onImageChange(image: string): void
    showPreview?: boolean
    squareCrop?: boolean
}

export const ImageCropper = React.forwardRef(
    (
        props: ImageCropperProps,
        ref: React.ForwardedRef<ReactCropperElement>
    ) => {
        const { classes: localClasses } = useLocalStyles()
        const [cropData, setCropData] = useState('#')
        const cropperRef = useForwardedRef<ReactCropperElement>(ref)

        const getCropData = () => {
            if (typeof cropperRef.current?.cropper !== 'undefined') {
                setCropData(
                    cropperRef.current?.cropper
                        .getCroppedCanvas({
                            width: 100,
                            height: 100,
                            fillColor: 'white',
                        })
                        .toDataURL()
                )
            }
        }

        return (
            <div>
                <div className={localClasses.defaultImageContainer}>
                    <Cropper
                        style={{ height: 400, width: '100%' }}
                        initialAspectRatio={1}
                        aspectRatio={props.squareCrop ? 1 : undefined}
                        preview=".img-preview"
                        src={props.selectedImage}
                        ref={cropperRef}
                        viewMode={1}
                        guides
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive
                        checkOrientation={false}
                    />
                </div>
                {props.showPreview && (
                    <div className={localClasses.croppedImageContainer}>
                        <div
                            className={localClasses.croppedImageHeaderAndButton}
                        >
                            <Typography variant="h3">Cropped Image</Typography>
                            <button onClick={getCropData}>Crop Image</button>
                        </div>
                        <div
                            className={
                                localClasses.croppedImagePreviewContainer
                            }
                        >
                            <img
                                className={localClasses.croppedImage}
                                src={cropData}
                                alt=""
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }
)

const useLocalStyles = makeStyles()({
    defaultImageContainer: { display: 'flex', flexDirection: 'column' },
    croppedImageContainer: {
        width: '100%',
        display: 'inline-block',
        padding: '10px',
        boxSizing: 'border-box',
    },
    croppedImageHeaderAndButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        padding: '5px',
    },
    croppedImagePreviewContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    croppedImage: { maxWidth: '100%' },
})
