import {
    ErrorMessage,
    RPUser,
    User,
    colors,
} from '@hazadapt-git/public-core-base'
import axios, { AxiosResponse } from 'axios'
import { isa, isaPublic } from '../api'
import { toast } from './misc'
import {
    IoAlertCircle,
    IoCheckmark,
    IoCheckmarkCircle,
    IoCloudUploadOutline,
} from 'react-icons/io5'
import { errorColor, primaryIconSize, successColor } from '../styles/universal'
import { store } from '../store'
import { setUser, updateProfilePictureURI, updateUser } from '../slices/base'

export const getUser = async (): Promise<RPUser> => {
    const response: AxiosResponse<RPUser> = await isa.get('/profile')
    return response.data
}

export const getPublicUser = async (): Promise<User> => {
    const response: AxiosResponse<User> = await isaPublic.get('/profile')
    return response.data
}

/**
 * Get a signed URL for the user's profile picture
 * @returns string
 */
export const getPublicProfilePicture = async (): Promise<string> => {
    try {
        const response: AxiosResponse<string> = await isaPublic.get(
            '/profile/profile-picture'
        )

        if (response.status !== 200) {
            throw new Error(ErrorMessage.ISA_REQUEST_FAILED)
        }

        // Test that image file exists at URL (will jump into catch block if it fails)
        await axios.head(response.data)

        // Signed URL
        return response.data
    } catch (e) {
        console.error(e)
        throw ErrorMessage.FS_REQUEST_FAILED
    }
}

export const saveUserChanges = async (user: RPUser): Promise<void> => {
    try {
        const response: AxiosResponse<RPUser> = await isa.put('/profile', user)
        store.dispatch(setUser(response.data))
        toast(
            'Changes saved.',
            <IoCheckmarkCircle
                color={colors.primary.WAIKATO}
                size={primaryIconSize}
            />
        )
    } catch (err) {
        console.error(err)
        toast(
            'Unable to save changes to your profile. Please try again.',
            <IoAlertCircle color={errorColor} size={primaryIconSize} />
        )
        throw err
    }
}

/**
 * Uploads the user's profile picture to S3
 * @param image the profile picture file
 * @returns a signed URL for the profile picture
 */
export const updateProfilePicture = async (image: File): Promise<void> => {
    // Attach profile picture to the POST request
    toast(
        'Saving...',
        <IoCloudUploadOutline
            color={colors.primary.BLUEBERRY}
            size={primaryIconSize}
        />
    )
    const formData = new FormData()
    formData.append('profile', image)
    try {
        const response: AxiosResponse<string> = await isa.post(
            '/profile-picture',
            formData,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            }
        )
        toast(
            'Profile picture updated!',
            <IoCheckmark color={successColor} size={primaryIconSize} />
        )
        store.dispatch(updateProfilePictureURI(response.data))
    } catch (err) {
        console.error(err)
        toast(
            'There was an error uploading your profile picture. Please try again.',
            <IoAlertCircle color={errorColor} size={primaryIconSize} />
        )
    }
}

export const importProfilePictureFromHazAdaptID = async (): Promise<void> => {
    try {
        const response: AxiosResponse<string> = await isa.put(
            '/profile-picture/import'
        )
        store.dispatch(updateUser({ profile_picture_uri: response.data }))
    } catch (err) {
        console.error(err)
        toast(
            'There was an error importing from your HazAdapt ID. Please try again.',
            <IoAlertCircle color={errorColor} size={primaryIconSize} />
        )
    }
}

export const resendInvitation = async (
    rp_user_id: number
): Promise<RPUser[]> => {
    const response: AxiosResponse<RPUser[]> = await isa.post(
        `/invite/${rp_user_id}/resend`
    )
    return response.data
}
