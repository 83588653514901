import MuiCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { CheckboxProps as MuiCheckboxProps } from '@mui/material'
import React, { FC } from 'react'

interface CheckboxProps extends MuiCheckboxProps {
    label: string | React.ReactNode
    labelPlacement?: 'top' | 'start' | 'bottom' | 'end'
}

export const Checkbox: FC<CheckboxProps> = (props: CheckboxProps) => (
    <FormControlLabel
        label={props.label}
        labelPlacement={props.labelPlacement}
        control={<MuiCheckbox {...props} />}
    />
)
