import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import React, { CSSProperties, FC } from 'react'

interface TextWithIconProps {
    icon: string | React.ReactNode
    children: React.ReactNode
    iconLocation?: 'left' | 'right'
    textStyle?: CSSProperties
    containerStyle?: CSSProperties
    spacing?: number
    spaceBetween?: boolean
    iconAlt?: string
    variant?:
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'subtitle1'
        | 'subtitle2'
        | 'body1'
        | 'body2'
        | 'caption'
        | 'button'
        | 'overline'
        | 'inherit'
}

export const TextWithIcon: FC<TextWithIconProps> = (
    props: TextWithIconProps
) => {
    const useLocalStyles = makeStyles()({
        container: {
            gap: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: props.spaceBetween ? 'space-between' : 'center',
            flexDirection:
                props.iconLocation === 'right' ? 'row-reverse' : 'row',
        },
    })

    const { classes } = useLocalStyles()
    return (
        <div className={classes.container} style={props.containerStyle}>
            {typeof props.icon === 'string' ? (
                <img src={props.icon} width="100%" alt={props.iconAlt} />
            ) : (
                props.icon
            )}
            <Typography variant={props.variant} pb={0} style={props.textStyle}>
                {props.children}
            </Typography>
        </div>
    )
}
