import { RPUser, colors } from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import { RPLogo } from '../atoms'
import { ProfileLinkWithAvatar } from '../molecules'
import { NavBarItem } from '../../lib/entities'
import HazAdaptLogo from './../../assets/images/HazAdaptLogoRW.svg'

interface MobileSiteNavProps {
    user?: RPUser
    onLogoPress(): void
    onToggleMenu(): void
    navItems: NavBarItem[]
    onNavItemPress(route: string): void
    activeNavItem: string
    mobileMenuOpen: boolean
}

export const MobileSiteNav: FC<MobileSiteNavProps> = ({
    user,
    onLogoPress,
    onToggleMenu,
    navItems,
    onNavItemPress,
    activeNavItem,
    mobileMenuOpen,
}) => {
    const { classes: localClasses } = useLocalStyles()

    const handleLinkPress = (e: React.MouseEvent) => {
        window.location.href = 'https://hazadapt.com'
    }

    const handleAvatarPress = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    if (!user) return null

    return (
        <Drawer
            onClick={onToggleMenu}
            open={mobileMenuOpen}
            anchor="right"
            sx={{
                '& .MuiDrawer-paper': {
                    width: '15rem',
                    backgroundColor: colors.primary.CERULEAN,
                    transition: 'all 0.225s ease',
                },
            }}
        >
            <Box
                className={localClasses.avatarContainer}
                onClick={handleAvatarPress}
            >
                <ProfileLinkWithAvatar
                    src={user.profile_picture_uri ?? undefined}
                    name={user.first_name}
                />
            </Box>
            <Box className={localClasses.logoContainer}>
                <RPLogo
                    onClick={() => onLogoPress()}
                    className={localClasses.rpLogo}
                />
            </Box>
            <Box className={localClasses.content}>
                {navItems
                    .filter((item) => !item.hide)
                    .map((item, index) => (
                        <div
                            onClick={() => onNavItemPress(item.route)}
                            className={localClasses.navBarLinkContainer}
                            key={item.label}
                        >
                            {item.icon && <>{item.icon}</>}
                            <Typography
                                component="span"
                                className={localClasses.navBarLink}
                            >
                                {item.label}
                                <Box
                                    className={localClasses.activeItemIndicator}
                                    sx={{
                                        backgroundColor:
                                            activeNavItem.startsWith(item.route)
                                                ? colors.grays.BLANC
                                                : 'inherit',
                                    }}
                                />
                            </Typography>
                        </div>
                    ))}
            </Box>
            <Box className={localClasses.footer} onClick={handleLinkPress}>
                <Typography className={localClasses.footerText}>
                    Powered By
                </Typography>
                <img
                    src={HazAdaptLogo}
                    alt={'HazAdapt Logo'}
                    className={localClasses.footerLogo}
                />
            </Box>
        </Drawer>
    )
}

const useLocalStyles = makeStyles()({
    avatarContainer: {
        padding: '1.4rem 1rem',
        position: 'absolute',
        top: 0,
        right: 0,
    },
    logoContainer: {
        display: 'flex',
        height: '2rem',
        justifyContent: 'start',
        marginTop: '7rem',
    },
    rpLogo: {
        cursor: 'pointer',
        height: '1.5rem',
        marginLeft: '1rem',
        width: 'auto',
    },
    content: {
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        marginLeft: '1rem',
        marginTop: '2rem',
    },
    footer: {
        alignItems: 'center',
        bottom: 0,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: '.5rem',
        position: 'absolute',
        width: '100%',
    },
    footerText: {
        color: colors.grays.BLANC,
        fontSize: '1rem',
        fontWeight: 500,
        paddingBottom: '.25rem',
        paddingRight: '1rem',
        whiteSpace: 'nowrap',
    },
    footerLogo: { height: '2rem', width: 'auto' },
    navBarLinkContainer: {
        alignItems: 'center',
        color: colors.grays.BLANC,
        cursor: 'pointer',
        display: 'flex',
        gap: '.5rem',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    },
    navBarLink: {
        color: 'inherit',
        fontWeight: 500,
    },
    activeItemIndicator: {
        boxSizing: 'border-box',
        height: '1.5px',
        width: '100%',
    },
})
