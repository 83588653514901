import { colors } from '@hazadapt-git/public-core-base'
import Help from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import MuiPopover from '@mui/material/Popover'
import { PopoverOrigin } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, {
    FC,
    useState,
    MouseEvent,
    CSSProperties,
    MouseEventHandler,
} from 'react'

import { theme, useStyles } from '../../lib/styles/universal'
import { Markdown } from './Markdown'

interface PopoverProps {
    helpText: string
    popoverPlacement?: 'left' | 'right' | 'center'
    popoverPaperStyle?: CSSProperties
    popoverTextStyle?: CSSProperties
    onClick?: MouseEventHandler<HTMLDivElement>
    icon?: React.ReactNode
    color?: string
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
}

export const Popover: FC<PopoverProps> = (props: PopoverProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => setAnchorEl(null)
    const open = Boolean(anchorEl)
    const { classes } = useStyles()

    const useLocalStyles = makeStyles()({
        container: {
            ...props.popoverTextStyle,
            cursor: 'default',
            margin: '1rem',
            width: 'fit-content',
            maxWidth: '80vw',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '30rem',
            },
        },
    })
    const { classes: localClasses } = useLocalStyles()

    return (
        <div className={classes.helpIcon} onClick={props.onClick}>
            <IconButton
                sx={{ color: props.color ?? colors.grays.NIMBUS }}
                onClick={handleClick}
            >
                {props.icon ?? <Help />}
            </IconButton>
            <MuiPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            ...props.popoverPaperStyle,
                            backgroundColor: colors.grays.WHITESMOKE,
                            borderRadius: '1rem',
                        },
                    },
                }}
                anchorOrigin={
                    props.anchorOrigin ?? {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                }
                transformOrigin={
                    props.transformOrigin ?? {
                        vertical: 'bottom',
                        horizontal:
                            props.popoverPlacement === 'left'
                                ? 'right'
                                : props.popoverPlacement === 'right'
                                ? 'left'
                                : 'center',
                    }
                }
            >
                <Markdown
                    containerClassName={localClasses.container}
                    content={props.helpText}
                />
            </MuiPopover>
        </div>
    )
}
