import React, { FC } from 'react'

import { LoadingView, SplashPageTemplate } from '../components'
import { PageProps } from '../lib/entities'
import { login } from '../lib/utils/auth'
import { OrganizationOverview } from '@hazadapt-git/public-core-base'
import { getSplashOrgDetails } from '../lib/utils'
import { getEnvVars } from '../lib/config'

interface SplashPageProps extends PageProps {}

const { rpUriBase } = getEnvVars()
const SplashPage: FC<SplashPageProps> = ({ loading }) => {
    const [organization, setOrganization] =
        React.useState<OrganizationOverview>()

    React.useEffect(() => {
        if (!organization) return
        document.title = `Log in to ${organization.name} on ResiliencePoint`
    }, [organization])

    React.useEffect(() => {
        getSplashOrgDetails()
            .then(setOrganization)
            .catch((err) => {
                console.error(err)
                window.location.href = `https://${rpUriBase}`
            })
    }, [])

    return !loading && organization ? (
        <SplashPageTemplate organization={organization} signIn={login} />
    ) : (
        <LoadingView />
    )
}

export default SplashPage
