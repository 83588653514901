import React, { FC } from 'react'
import Drawer from '@mui/material/Drawer'

interface DrawerProps {
    content: React.ReactElement
    open: boolean
    anchor?: string
    onClose: React.MouseEventHandler
}

export const DrawerMenu: FC<DrawerProps> = (props: DrawerProps) => {
    return (
        <Drawer
            open={props.open}
            onClose={props.onClose}
            anchor={'right'}
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: '30rem',
                    overflowX: 'hidden',
                },
            }}
        >
            {props.content}
        </Drawer>
    )
}
