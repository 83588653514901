import React, { FC } from 'react'

import PublicAppIconSVG from '../../assets/images/HazAdaptAppIcon.svg'
import { useStyles } from '../../lib/styles/universal'

interface PublicAppIconProps {}

export const PublicAppIcon: FC<PublicAppIconProps> = () => {
    const { classes } = useStyles()
    return (
        <img
            src={PublicAppIconSVG}
            className={classes.publicAppIcon}
            alt="HazAdapt app icon"
        />
    )
}
