import { colors, TrendingHazardDTO } from '@hazadapt-git/public-core-base'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import { FC } from 'react'

import { HorizontalScroller, TrendingHazardItem } from '../molecules'
import { IoInformationCircle, IoTrendingUp } from 'react-icons/io5'
import { Popover } from '../atoms'
interface TrendingHazardListProps {
    data: TrendingHazardDTO[]
    showErrorText?: boolean
    onPress(id: number): void
    loading?: boolean
}

export const TrendingHazardList: FC<TrendingHazardListProps> = (
    props: TrendingHazardListProps
) => {
    const { classes: localClasses } = useLocalStyles()

    return (
        <Grid container className={localClasses.container} gap=".5rem">
            <Grid item xs={12} className={localClasses.contents}>
                <IoTrendingUp color={colors.secondary.HEAT_WAVE} size="2rem" />
                <Typography
                    variant="h4"
                    display="flex"
                    alignItems="center"
                    color={colors.primary.CERULEAN}
                >
                    Trending Hazards
                    <Popover
                        helpText="Trending Hazards highlight the Hazard Guides receiving the most interactions locally within the past week. Note: This may differ from HazAdapt's global trends."
                        icon={
                            <IoInformationCircle
                                color={colors.primary.CERULEAN}
                            />
                        }
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    />
                </Typography>
            </Grid>
            {props.loading ? (
                <div className={localClasses.contents}>
                    {[...Array(5)].map((_, index) => (
                        <Skeleton
                            key={`trendingHazardItem_skeleton_${index + 1}`}
                            variant="rectangular"
                            className={localClasses.skeleton}
                        />
                    ))}
                </div>
            ) : props.data.length < 1 ? (
                <Typography
                    sx={{
                        color: colors.grays.THUNDERCLOUD,
                        marginLeft: '1rem',
                    }}
                >
                    {props.showErrorText
                        ? 'Unable to load trending hazards.'
                        : 'Not enough data'}
                </Typography>
            ) : (
                <HorizontalScroller styles={{ paddingLeft: '1rem' }}>
                    {props.data.map((item, index) => (
                        <TrendingHazardItem
                            {...item}
                            onPress={() => props.onPress(item.id)}
                            fontShade="light"
                            key={`trendingHazardItem_${item.id}`}
                        />
                    ))}
                </HorizontalScroller>
            )}
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'grid',
        margin: '.5rem',
        backgroundColor: colors.grays.BLANC,
        border: `1px solid ${colors.primary.CERULEAN}`,
        borderRadius: '.5rem',
        width: 'auto',
        boxSizing: 'border-box',
        minHeight: '7rem',
        padding: '.25rem 0 .75rem',
    },
    contents: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        marginLeft: '1rem',
    },
    skeleton: { borderRadius: '.5rem', height: '2.75rem', width: '13rem' },
})
