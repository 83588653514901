import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ProfileDetailsForm } from './ProfileDetailsForm'

interface ManageProfileViewProps {
    header?: string
    firstName: string
    lastName: string
    title: string
    email: string
    hazadapt_id_email: string
    profilePicture?: string
    disableEmail?: boolean
    onFirstNameChange(value: string): void
    onLastNameChange(value: string): void
    onTitleChange(value: string): void
    onEmailChange(value: string): void
    onImportClick: React.MouseEventHandler
    onChangeProfilePicture(image?: string): void
    avatarOptions: string[]
    pendingChanges?: boolean
    mode: 'setup' | 'manage'
    profilePictureFormRef?: React.RefObject<HTMLDivElement>
}

export const ManageProfileView: FC<ManageProfileViewProps> = (
    props: ManageProfileViewProps
) => {
    const { classes: localClasses } = useLocalStyles()

    return (
        <div className={localClasses.container}>
            <ProfileDetailsForm
                firstName={props.firstName}
                lastName={props.lastName}
                title={props.title}
                email={props.email}
                hazadapt_id_email={props.hazadapt_id_email}
                disableEmail={props.disableEmail}
                onFirstNameChange={props.onFirstNameChange}
                onLastNameChange={props.onLastNameChange}
                onTitleChange={props.onTitleChange}
                onEmailChange={props.onEmailChange}
                mode={props.mode}
                profilePicture={props.profilePicture}
                onImportClick={props.onImportClick}
                onChangeProfilePicture={props.onChangeProfilePicture}
                avatarOptions={props.avatarOptions}
                profilePictureFormRef={props.profilePictureFormRef}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        margin: '0 auto',
        width: '100%',
        maxWidth: '70rem',
    },
})
